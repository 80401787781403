import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getUserProfileService } from '../services/userService';

interface UserData {
  id: string;
  username: string;
  role: ChildofRoles;
}

interface ChildofRoles {
  id: string;
  title: string;
}

interface AuthDataContextType {
  userData: UserData | null;
  setUserData: (data: UserData | null) => void;
}

const AuthDataContext = createContext<AuthDataContextType | undefined>(undefined);

export const AuthDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);  

  useEffect(() => {

    const ignorePaths = ['/login'];
    if (ignorePaths.includes(window.location.pathname)) {
      return; 
    }
   
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          console.log('--- fetch profile ---')
          const profileData = await getUserProfileService();
          setUserData(profileData);
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <AuthDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </AuthDataContext.Provider>
  );
};

export const useAuthData = () => {
  const context = useContext(AuthDataContext);
  if (context === undefined) {
    throw new Error('useAuthData must be used within an AuthDataProvider');
  }
  return context;
};
