import { Box, Button, ButtonGroup, InputLabel, MenuItem, Select, TextField, Typography, FormControl, Modal, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect, useState } from "react";
import { createProjectService, getCompanyService, getProjectService } from "../../services/projectService";
import { alertError, alertSuccess } from "../../utils/notification";

interface Company {
    id: number;
    title: string;
}

interface ProjectData {
    id: number;
    title: string;
    sub_project: SubProject[];
}

interface SubProject {
    id: number;
    title: string;
}

interface ActionProjectComponentProps {
    refreshProjects: () => void;
}

const ActionSubProjectComponent: React.FC<ActionProjectComponentProps> = ({ refreshProjects }) => {

    const [openCreateMainProject, setOpenCreateMainProject] = useState(false);
    const [openCreateSubProject, setOpenCreateSubProject] = useState(false);

    const [title, setTitle] = useState('');
    const [companyId, setCompanyId] = useState<number | string>('');
    const [projectId, setProjectId] = useState<number | string>('');
    const [companies, setCompanies] = useState<Company[]>([]);
    const [projects, setProjects] = useState<ProjectData[]>([]);
    const [error, setError] = useState<string | null>(null);

    const [confirmMainOpen, setConfirmMainOpen] = useState(false);
    const [confirmSubOpen, setConfirmSubOpen] = useState(false);

    const fetchCompanies = async () => {
        try {
            const companyData = await getCompanyService();
            const projectData = await getProjectService();

            setProjects(projectData);
            setCompanies(companyData);
        } catch (error) {
            console.error('Failed to fetch companies:', error);
        }
    };


    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const companyData = await getCompanyService();
                const projectData = await getProjectService();

                setProjects(projectData);
                setCompanies(companyData);
            } catch (error) {
                console.error('Failed to fetch companies:', error);
            }
        };

        fetchCompanies();
    }, []);

    const handleCloseCreateMainProject = () => {
        setOpenCreateMainProject(false);
        setConfirmMainOpen(false);
        setTitle('');
        setCompanyId('');
        setError('');
    };

    const handleCloseCreateSubProject = () => {
        setOpenCreateSubProject(false);
        setConfirmSubOpen(false);
        setTitle('');
        setProjectId('');
        setError('');
    };

    const handleGenerateMain = async () => {

        if (!title || !companyId) {
            setError('All fields are required.');
            return;
        } else {
            setConfirmMainOpen(true);
            setError('');
        }

    };

    const handleGenerateSub = async () => {

        if (!title || !projectId) {
            setError('All fields are required.');
            return;
        } else {
            setConfirmSubOpen(true);
            setError('');
        }

    };

    const handleConfirmMainCreate = async () => {
        try {
            const newProject = { title, company_id: companyId, sub_project: null };
            await createProjectService(newProject);
            refreshProjects();
            handleCloseCreateMainProject();
        } catch (error) {
            console.error('Failed to create project:', error);
        }
    }

    const handleConfirmSubCreate = async () => {
        try {
            const companyInfo : any = projects.find((i)=> i.id === projectId);
            const newProject = { title, company_id: companyInfo.company_id , sub_project: projectId };
            await createProjectService(newProject);
            refreshProjects();
            handleCloseCreateSubProject();

            alertSuccess('Add Successfully')
        } catch (error) {
            console.error('Failed to create project:', error);
            alertError('Service Fail')
        }
    }

    const handleOnOpenModal = async (isMain : boolean) => {
        await fetchCompanies()

        if(isMain){
            setOpenCreateMainProject(true)
        }else{
            setOpenCreateSubProject(true)
        }
    }


    return (
        <>

            <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button onClick={() => handleOnOpenModal(true)}>Add Main Project</Button>
                <Button onClick={() => handleOnOpenModal(false)}>Add Sub Project</Button>
            </ButtonGroup>


            {/* create main project */}
            <Modal
                open={openCreateMainProject}
                onClose={handleCloseCreateMainProject}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Add New Main Project
                    </Typography>
                    <TextField
                        focused
                        className="mb-0"
                        fullWidth
                        margin="normal"
                        label="Project Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        error={!!error && !title}
                    />
                    {!!error && !title && <Typography color="error">Project name is required</Typography>}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="company-label">Company</InputLabel>
                        <Select
                            labelId="company-label"
                            value={companyId}
                            onChange={(e) => setCompanyId(e.target.value)}
                            label="Company"
                            error={!!error && !companyId}
                        >
                            {companies.map(company => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {!!error && !companyId && <Typography color="error">Company is required</Typography>}
                    </FormControl>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseCreateMainProject} sx={{ mr: 1 }}>Cancel</Button>
                        <Button color="primary" onClick={handleGenerateMain}>Create</Button>
                    </Box>
                </Box>
            </Modal>

            {/* create sub project */}
            <Modal
                open={openCreateSubProject}
                onClose={handleCloseCreateSubProject}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Add New Sub Project
                    </Typography>
                    <TextField
                        focused
                        className="mb-0"
                        fullWidth
                        margin="normal"
                        label="Project Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}

                        error={!!error && !title}
                    />
                    {!!error && !title && <Typography color="error">Project title is required</Typography>}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Main Project</InputLabel>
                        <Select
                            labelId="main-project-label"
                            value={projectId}
                            onChange={(e) => setProjectId(e.target.value)}
                            label="Project"

                            error={!!error && !projectId}
                        >
                            {projects.map(project => (
                                <MenuItem key={project.id} value={project.id}>
                                    {project.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {!!error && !projectId && <Typography color="error">Main Project is required</Typography>}
                    </FormControl>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseCreateSubProject} sx={{ mr: 1 }}>Cancel</Button>
                        <Button color="primary" onClick={handleGenerateSub}>Create</Button>
                    </Box>
                </Box>
            </Modal>

            <Dialog open={confirmMainOpen} onClose={() => setConfirmMainOpen(false)}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to create the project "{title}"?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmMainCreate} variant="contained" color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setConfirmMainOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={confirmSubOpen} onClose={() => setConfirmSubOpen(false)}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to create the sub project "{title}"?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmSubCreate} variant="contained" color="primary">
                        Yes
                    </Button>
                    <Button onClick={() => setConfirmMainOpen(false)}>No</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ActionSubProjectComponent;