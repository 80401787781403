import { useEffect, useState } from "react";
import PdfGenerator from "../../components/pdf/PdfComponent";
import { fetchImageByUrl, getOneClippingService } from "../../services/clippingService";
import { PDFViewer } from "@react-pdf/renderer";
import { Buffer } from 'buffer';
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

const ViewClippingPdfPage: React.FC = () => {

    const { id } = useParams();
    const [data , setData] = useState<any>();
    const [imgList, setImgList] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const response = await getOneClippingService(parseInt(id));
                    const fetchedImgList = response?.imgList || [];
                    // get img list
                    let imgList = [];
                    for(const index in fetchedImgList){
                        imgList.push(fetchedImgList[index]?.img_sources[0].img_source);
                    }
                    // get media 
                    let imgMedia = response?.category?.imgSource?.imgUrl ? response.category.imgSource.imgUrl : '';
                    
                    // make payload
                    const payload = {
                        imgList : [
                            {
                                img_media : imgMedia,
                                img_content : imgList
                            }
                        ]
                    }

                    const imgInfo = await fetchImageByUrl(payload);
                    setImgList(imgInfo);
                    setData(response);
                } catch (error) {
                    console.error("Failed to fetch clipping:", error);
                }
            }
        };

        fetchData();
    }, [id]);

    return  (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between mb-2">
                <h3>PDF Clipping Detail</h3>
                <Button
                    onClick={() => {
                        window.location.href = '/clipping';
                    }}
                    variant="contained"
                    color="primary"
                >
                    Clipping
                </Button>
            </div>
            <div style={{ height: '100vh' }}>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <PdfGenerator imgList={imgList} data={data} />
                </PDFViewer>
            </div>
        </div>
    );
}

export default ViewClippingPdfPage;