import axiosInstance from "./axiosInterceptor";

const API_ENDPOINTS = {
    findAll: '/users/findAll', 
    profile: '/users/profile', 
    generate: '/users/create', 
    edit: '/users/edit', 
    del: '/users/delete', 
};

export const getUserProfileService = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.profile);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};

export const getUserFindAllService = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.findAll);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};

export const getGenerateUserService = async (payload : any) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.generate, payload);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};

export const editUserService = async (payload : any) => {
  try {
    const response = await axiosInstance.put(API_ENDPOINTS.edit, payload);
    return response.data;
  } catch (error : any) {
    throw new Error(error.response?.data?.message);
  }
};

export const delUserService = async (id : number) => {
  try {
    const response = await axiosInstance.delete(`${API_ENDPOINTS.del}/${id}`);
    return response.data;
  } catch (error : any) {
    throw new Error(error.response?.data?.message);
  }
};
