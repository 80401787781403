import React from "react";
import { Text, View, StyleSheet, Link, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    bold: {
        fontWeight: "bold",
    },
    white: {
        color: "#fff",
    },
    fontS: {
        fontSize: 8,
    },
    fontM: {
        fontSize: 12,
        paddingVertical: 6,
    },
    fontL: {
        fontSize: 14,
    },
    fontBGcolor: {
        backgroundColor: "#00fe00",
    },
    Logo: {
        fontSize: 18,
        textAlign: "center",
        paddingTop: "20px",
        paddingBottom: "5px",
    },
    BGblue: {
        backgroundColor: '#05768b',
    },

    table: {
        flexDirection: 'column',
        width: "auto",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        maxHeight: 100
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomWidth: 1,
        borderRightWidth: 1,
    },
    tableWidthLeft: {
        width: 130,
        minWidth: 130,
        alignItems: "center",
    },
    tableWidthMiddle: {
        width: "100%",
        padding: 5
    },
    tableWidthRight: {
        width: 150,
        minWidth: 150
    },
    cellText: {
        width: "100%",
        borderStyle: "solid",
        borderColor: "#000",
        textAlign: "center",
        padding: 4,
    },
    tableColMiddle: {
        width: "33.33%",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        textAlign: "center",
        padding: 4,
    },
    tableColMiddleRight: {
        width: "33.33%",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        textAlign: "center",
        padding: 4,
    },
    tableColBottom: {
        width: "33.33%",
        height: "60px",
        borderStyle: "solid",
        borderColor: "#000",
        borderRightWidth: 1,
        textAlign: "center",
        paddingTop: "20px"
    },
    tableColBottomRight: {
        width: "33.33%",
        height: "60px",
        borderStyle: "solid",
        borderColor: "#000",
        textAlign: "center",
        paddingTop: "20px"
    },
    simpleBoderRight: {
        width: "33.33%",
        borderStyle: "solid",
        borderColor: "#000",
        borderRightWidth: 1,
        flexGrow: 1,

        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    tableColx: {
        flexDirection: "row",
        flexGrow: 1,
    },
    textCenter: {
        alignSelf: 'center'
    },
    fontBolder: {
        fontFamily: 'Helvetica',
        fontWeight: 'bold'
    },
    clippingValueContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 5,
        marginRight: 10,
    },
    clippingContainer: {
        flexDirection: 'row',
    },
    clippingValue: {
        fontWeight: 'normal'
    },
    clippingVariable: {
        paddingLeft: 0,
        fontWeight: 'bold'
    },
    ignoreBorderRight: {
        border: 'none'
    },
    backGroundGreen: {
        backgroundColor: '#00fe00'
    },
    logoImg: {
        padding: 5,
        width: 120,
        height: 80,
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    linkStyle: {
        textDecoration: 'none'
    }
});


interface headerPdfProps {
    mediaType: string;
    data: any;
    mediaImg: any;
}

const HeaderPDF: React.FC<headerPdfProps> = ({ mediaType, data, mediaImg }) => {

    const IsTVnRadio = ["TV", "Radio"];
    const isPrint = ["Print"];
    const isOnline = ["Online"];

    const convertToThaiDate = (dateString: string) => {
        const days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'];
        const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

        const date = new Date(dateString);
        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear() + 543;

        return `${day} ${dayOfMonth} ${month} ${year}`;
    }

    const extractMainDomain = (url: string) => {
        if (!url) return '-';
        let domain = url.replace(/(^\w+:|^)\/\//, '');

        domain = domain.split('/')[0];
        if (domain.startsWith('www.')) {
            domain = 'https://' + domain;
        } else if (!domain.startsWith('http://') && !domain.startsWith('https://')) {
            domain = 'https://www.' + domain;
        }

        return domain;
    }

    console.log(data)
    console.log(data?.category?.category?.title)

    return (

        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={[styles.tableCol, styles.tableWidthLeft, styles.logoContainer]}>
                    {mediaImg?.contentType ? <Image style={styles.logoImg} src={`data:${mediaImg.contentType};base64,${mediaImg.imgBase64}`} /> : <Text>{data?.category?.title}</Text>}
                    {
                        isPrint.includes(data?.category?.category?.title) && (<Text style={styles.fontS}>Circulation: {data?.category?.print_count_newspaper} </Text>)
                    }
                    {
                        isOnline.includes(data?.category?.category?.title) && (<Text style={styles.fontS}>Site Visitor Per Month: {data?.category?.site_visitor_per_month} </Text>)
                    }
                </View>
                <View style={[styles.tableCol, styles.tableWidthMiddle]}>
                    <View style={[styles.fontM, styles.clippingValueContainer]}>
                        <Text style={styles.clippingVariable} >Date :</Text>
                        <Text>{convertToThaiDate(data.select_date)}</Text>
                    </View>
                    <View style={[styles.clippingContainer, styles.fontM]}>
                        {isPrint.includes(data?.category?.category?.title) && (<View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >Col.Inch :</Text> <Text>{data?.category?.price || 0}</Text></View>)}
                        {IsTVnRadio.includes(data?.category?.category?.title) && (<View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >Publishing :</Text> <Text>{data?.publishing || 0} นาที</Text></View>)}
                        {isPrint.includes(data?.category?.category?.title) && (<View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >Clip :</Text> <Text>สี่สี</Text></View>)}
                        <View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >AD Value :</Text> <Text>{data?.ad_value || 0}</Text></View>
                        <View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >PR Value :</Text> <Text>{data?.pr_value || 0}</Text></View>
                    </View>
                   
                        {
                            IsTVnRadio.includes(data?.category?.category?.title) &&
                            (
                            <View style={[styles.fontM, styles.clippingValueContainer]}>
                                <View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >Clip Url :</Text> <Link style={styles.linkStyle} src={data.clip_url}>{extractMainDomain(data.clip_url)}</Link></View>
                            </View>)
                        }
                    
                        {
                            isOnline.includes(data?.category?.category?.title) &&
                            (
                            <View style={[styles.fontM, styles.clippingValueContainer]}>
                                <View style={styles.clippingValueContainer} ><Text style={styles.clippingVariable} >Url :</Text> <Link style={styles.linkStyle} src={data.sourceUrl}>{extractMainDomain(data.sourceUrl)}</Link></View>
                            </View>)
                        }

                    <View style={[styles.fontM, styles.clippingValueContainer]}>
                        <Text style={styles.clippingVariable} >Headline :</Text>
                        <Text>{data?.title || '-'}</Text>
                    </View>
                </View>
                <View style={[styles.tableCol, styles.tableWidthRight]}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.cellText, styles.fontL, styles.white, styles.BGblue]}>PR Potential</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableColMiddle, styles.fontS]}>PR Score</Text>
                        <Text style={[styles.tableColMiddle, styles.fontS]}>PR Credit </Text>
                        <Text style={[styles.tableColMiddleRight, styles.fontS]}>Sentiment</Text>
                    </View>
                    <View style={styles.tableColx}>
                        <View style={styles.simpleBoderRight}><Text style={[styles.fontM]}>{data?.pr_score || 0}</Text></View>
                        <View style={styles.simpleBoderRight}><Text style={[styles.fontM]}>{data?.pr_credit || '-'}</Text></View>
                        <View style={[styles.simpleBoderRight, styles.ignoreBorderRight]}><Text style={[styles.fontM, styles.backGroundGreen]}>{data?.sentiment_score || '-'}</Text></View>
                    </View>
                </View>
            </View>
        </View>
    )
};

export default HeaderPDF;
