import { toast } from "react-toastify";

export const alertSuccess = (msg:string) => {
    toast.success(msg, {
        position: "top-right",
    });
}

export const alertError = (msg:string) => {
    toast.error(msg, {
        position: "top-right",
    });
}