import { Button } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';


interface ImageUploadProps {
    onSubmit: (data: any) => void;
    Data?: any;
    isEditing?: boolean;
}

const ImageUploadPreview: React.FC<ImageUploadProps> = ({ onSubmit , Data, isEditing}) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [onDelete, setOnDelete] = useState(false);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result as string);
                onSubmit(file)
            };
            reader.readAsDataURL(file);
        }

        setOnDelete(false);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageRemove = () => {
        setSelectedImage(null);
        onSubmit(null);
        setOnDelete(true);
    };

    
    const getImg = () => {

        if(selectedImage){
            return selectedImage
        }else if(!selectedImage && !isEditing){
            return './icon/no-img.png'
        }else{
            return (Data?.imgSource?.imgUrl && !onDelete) ? Data?.imgSource?.imgUrl : './icon/no-img.png';
        }
    }

    useEffect(() => {
        if(Data?.imgSource?.imgUrl){
            onSubmit(Data?.imgSource?.imgUrl);
        }
    }, [Data]);

    return (
        <div className='d-flex flex-column align-items-center justify-content-center gap-1' style={{ textAlign: 'center' }}>
            
            <div className='position-relative'>
                {(selectedImage || (Data?.imgSource?.imgUrl && !onDelete)) && (<div 
                        className='position-absolute top-0 bg-danger rounded-circle' 
                        style={{ right : '6px' ,  width: '25px', height: '25px', cursor: 'pointer'  }}
                        onClick={handleImageRemove}
                    >
                    <img src="./icon/delete.png" alt="" style={{ width: '15px', height: '20px' }} />
                </div>)}
                <img
                    src={getImg()}
                    alt="Selected"
                    onClick={handleButtonClick}
                    className='rounded-circle shadow-lg'
                    style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                />
            </div>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <Button onClick={handleButtonClick}>
                Upload Image
            </Button>
        </div>
    );
};


export default ImageUploadPreview;
