import { Autocomplete, Box, Button, ButtonGroup, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import ActionClippingManageMentComponent from "./actionClippingManageMent";
import { useEffect, useState } from "react";
import { alertError, alertSuccess } from "../../utils/notification";
import { getAllProjectWithCompanyService, getCompanyService } from "../../services/projectService";
import { getMediaInfoService } from "../../services/newsService";
import styled from 'styled-components';
import { uploadFile } from "../../services/firebaseService";
import { createClippingService } from "../../services/clippingService";



interface ProjectOption {
    id: number;
    title: string;
    sub_project?: SubProjectOption[];
}

interface SubProjectOption {
    id: number;
    title: string;
}

interface CompanyOption {
    id: number;
    title: string;
}

const sentimentOption = [
    {
        score : 20,
        title : 'Positive'
    },
    {
        score : 10,
        title : 'Neutral'
    },
    {
        score : 0,
        title : 'Negative'
    },
]

const UploadClippingPage: React.FC = () => {

    // for add
    const [onOpenAddModal, setOnOpenAddModal] = useState(false);

    // ------ for get project id -----
    const [projectOption, setProjectOption] = useState<ProjectOption[] | undefined>();
    const [companyOption, setCompanyOption] = useState<ProjectOption[] | undefined>();

    const [selectedCompany, setSelectedCompany] = useState<CompanyOption | null>(null);
    const [selectedProject, setSelectedProject] = useState<ProjectOption | null>(null);
    const [selectedSubProject, setSelectedSubProject] = useState<SubProjectOption | null>(null);
    const [isSubProject, setIsSupProject] = useState(false);

    // for clipping list
    const [clippingList, setClippingList] = useState<any[]>([]);
    const [mediaOption, setMediaOption] = useState<any[]>();

    // for save clipping processing
    const [isSaving, setIsSaving] = useState(false);
    const [progress, setProgress] = useState<number>(0);

    // ------ for get project id -----
    const handleProjectChange = (projectId: number) => {
        // reset
        setIsSupProject(false);
        setSelectedSubProject(null);

        const project = projectOption?.find(p => p.id === projectId) || null;
        setSelectedProject(project);

        if (project && Array.isArray(project?.sub_project) && project?.sub_project?.length > 0) {
            setIsSupProject(true);
        } else {
            setIsSupProject(false);
        }
    };

    const handleCompanyChange = (companyId: number) => {

        setIsSupProject(false);
        setSelectedProject(null);
        setSelectedSubProject(null);

        const company = companyOption?.find(c => c.id === companyId) || null;
        setSelectedCompany(company);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const projects = await getAllProjectWithCompanyService();
                const companies = await getCompanyService();
                const medias = await getMediaInfoService();
                setProjectOption(projects);
                setCompanyOption(companies);
                setMediaOption(medias);

            } catch (err: any) {
                alertError('Service Error');
            }
        };
        fetchData();
    }, []);

    // ------ for get project id -----

    // ------ for validate open add modal -----
    const handleOpenAddClipping = () => {
        if (!selectedCompany || !selectedProject || (isSubProject && !selectedSubProject)) {
            alertError('Please select all required fields.');
            return;
        }
        setOnOpenAddModal(true);
    };
    // ------ for validate open add modal -----

    // for clipping list

    const handleRemoveClipping = (indexToRemove: number) => {
        setClippingList((prevList: any) => prevList.filter((_: any, index: number) => index !== indexToRemove));
    };

    const handleSaveClipping = async () => {
        try {

            if(clippingList.length === 0){
                alertError('Please Add News Clipping before save!');
                return;
            }

            setIsSaving(true);

            // data from meta data
            let project_id = (isSubProject ? selectedSubProject?.id : selectedProject?.id) || 0;
            let company_id = selectedCompany || 0;

            const itemsToSave = clippingList;
            const totalItems = itemsToSave.length;

            for (let index = 0; index < totalItems; index++) {
                try {
                    if (Array.isArray(itemsToSave[index].file)) {
                        const clippingImgs = await saveImg(itemsToSave[index].file);
                        itemsToSave[index].clippingImgs = clippingImgs;
                        delete itemsToSave[index].file;
                    } else {
                        itemsToSave[index].clippingImgs = [];
                        delete itemsToSave[index].file;
                    }
                    const sentiment_score = sentimentOption.find(i => i.score === parseInt(itemsToSave[index].sentiment_score))
                    let payload = { 
                        ...itemsToSave[index], 
                        project_id: project_id, 
                        sourceUrl: itemsToSave[index].url ? itemsToSave[index].url : '',
                        sentiment_score: sentiment_score?.title,
                        print_type: itemsToSave[index]?.print_type ? itemsToSave[index].print_type : ''
                    };
                    await createClippingService(payload);

                    const progress = ((index + 1) / totalItems) * 100;

                    setProgress(progress);
                    alertSuccess('Add Successfully')
                } catch (error) {
                    console.error("Error occurred while saving clipping:", error);
                    alertError('Service Error')
                }
            }
        } catch (error) {
            console.error("Error occurred while processing clippings:", error);
            alertError('Service Process Error')
        } finally {
            setTimeout(() => {
                setIsSaving(false);
                setProgress(0);
                setClippingList([]);
            }, 3000);
        }

    }

    const saveImg = async (fileList: File[]) => {
        const imgList = [];
        for (const file of fileList) {
            const imgUrl = await uploadFile(file, 'clipping');
            if (imgUrl) {
                imgList.push(imgUrl);
            }
        }
        return imgList;
    }

    const getOptionLabel = (option : CompanyOption) => option.title;


    return (
        <div className='py-2'>
            <div className='container-fluid card p-2'>
                <div>
                    <div className="d-flex justify-content-between w-100">
                        <b>Clipping Meta Data</b>
                        <ButtonGroup variant="contained" aria-label="Basic button group">
                            <Button onClick={()=>{
                                window.location.href = '/clipping'
                            }}>VIEW CLIPPING</Button>
                        </ButtonGroup>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <FormControl className="my-2" fullWidth size="small">
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    options={companyOption || []}
                                    getOptionLabel={getOptionLabel}
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                    label='Client *'
                                     />}
                                    onChange={(event, newInputValue) => {
                                        handleCompanyChange(newInputValue?.id || 0)
                                      }}
                                    disabled={clippingList.length !== 0}
                                    
                                /> 
                            </FormControl>
                        </div>
                        <div className="col-3">
                            <FormControl className="my-2" fullWidth size="small">
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    options={projectOption || []}
                                    getOptionLabel={getOptionLabel}
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                        label='Project *' />}
                                    onChange={(event, newInputValue) => {
                                        handleProjectChange(newInputValue?.id || 0)
                                      }}
                                      value={selectedProject || null} 
                                      disabled={(selectedCompany ? false : true) || (clippingList.length !== 0)}
                                    
                                /> 
                            </FormControl>
                        </div>
                        <div className="col-3">
                            {isSubProject && selectedProject && selectedProject.sub_project && (
                                <FormControl className="my-2" fullWidth size="small">
                                    <Autocomplete
                                        size="small"
                                        disablePortal
                                        options={selectedProject.sub_project || []}
                                        getOptionLabel={getOptionLabel}
                                        renderInput={(params) => <TextField 
                                            {...params} 
                                            label='Sub Project *' />}
                                        onChange={(event, newInputValue) => {
                                            setSelectedSubProject(selectedProject.sub_project!.find(sub => sub.id === Number(newInputValue?.id || 0)) || null)
                                        }}
                                        value={selectedSubProject || null} 
                                        disabled={clippingList.length !== 0}
                                        
                                /> 
                                </FormControl>
                                
                            )}
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="d-flex flex-column h-100">
                    <b>Clipping List</b>
                    <Button variant="contained" onClick={handleOpenAddClipping} >ADD NEWS CLIPPING</Button>
                    <div className="d-flex flex-column gap-1 flex-grow-1 my-1">

                        { (clippingList.length) === 0 && <div className="h-100 d-flex align-items-center justify-content-center">Add some news clipping !</div>

                        }

                        {clippingList?.map((clipping: any, index: number) => (
                            <div key={index} className="card position-relative p-2">
                                <div className="position-absolute" style={{ right: '5px', top: '5px' }}>
                                    <Button color="error" onClick={() => handleRemoveClipping(index)}>Remove</Button>
                                </div>
                                <div className="d-flex flex-column gap-2 mb-2">
                                    <b>Clipping Info</b>
                                    <div className="d-flex flex-column gap-1">
                                        <div className="d-flex gap-1">
                                            <b>Media :</b><span>{(mediaOption?.find((i) => i.id === clipping.category)).title || ''}</span>
                                        </div>
                                        <div className="d-flex gap-1">
                                            <b>Head line :</b><span>{clipping.title}</span>
                                        </div>

                                        {/* for pr */}
                                        <div className="d-flex gap-4">
                                            <div className="d-flex gap-1 ">
                                                <b>Media Score :</b><span>{clipping.media_score}</span>
                                            </div>
                                            <div className="d-flex gap-1 ">
                                                <b>Publishing :</b><span>{clipping.publishing}</span>
                                            </div>
                                            <div className="d-flex gap-1 ">
                                                <b>AD Value :</b><span>{clipping.ad_value}</span>
                                            </div>
                                            <div className="d-flex gap-1">
                                                <b>PR Score :</b><span>{clipping.pr_score}</span>
                                            </div>
                                            <div className="d-flex gap-1">
                                                <b>PR Credit :</b><span>{clipping.pr_credit}</span>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <b>Clipping Img</b>
                                    {
                                        clipping.file ? <div>
                                            {clipping.file.map((image: any, index: number) => (
                                                <div key={index} style={{ display: 'inline-block', position: 'relative' }}>
                                                    <img
                                                        src={image.preview}
                                                        alt={`preview-${index}`}
                                                        style={{ maxWidth: '150px', maxHeight: '150px', margin: '10px' }}
                                                    />
                                                </div>
                                            ))}
                                        </div> : <div className="text-center text-warning">No Image</div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <Button variant="contained" color="success" onClick={handleSaveClipping} >SAVE CLIPPING</Button>
                </div>

                <ActionClippingManageMentComponent
                    onSubmit={(data) => {
                        if (Array.isArray(clippingList) && clippingList !== undefined) {
                            setClippingList((prevClippingList: any) => [...prevClippingList, data]);
                        } else {
                            setClippingList([data]);
                        }
                        setOnOpenAddModal(false);
                    }}
                    onOpen={onOpenAddModal}
                    onClose={() => setOnOpenAddModal(false)}
                    Data={isSubProject ? selectedSubProject : selectedProject}
                />


                {isSaving &&
                    <ProgressModalContainer>
                        <ProgressContainer className="gap-2">
                            <b>Saving...</b>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress variant="determinate" value={progress} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="text.secondary"
                                    >{`${Math.round(progress)}%`}</Typography>
                                </Box>
                            </Box>
                            <b className="text-danger">Please do not navigate away from this page until the process is complete.</b>
                        </ProgressContainer>
                    </ProgressModalContainer>
                }

            </div>
        </div>
    );
};

export default UploadClippingPage;



// Styled components for the progress bar modal
const ProgressModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white; /* Background color of the modal */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;