import { createBrowserRouter, Navigate } from "react-router-dom";
import LoginPage from "../pages/Login";
import IsLoginRouter from "./Authentication";
import LayoutComponent from "../components/LayoutComponent";
import UserManageMentPage from "../pages/Users/UsersManageMent";
import ProjectPage from "../pages/Project";
import CompanyPage from "../pages/Company";
import MediaManageMentPage from "../pages/Media/MediaManageMent";
import ClippingPage from "../pages/Clipping";
import UploadClippingPage from "../pages/Clipping/uploadClippingPage";
import ViewClippingPdfPage from "../pages/Clipping/viewClippingPDF";
import ViewClippingPage from "../pages/Clipping/viewCilpping";
import BetaClippingPage from "../pages/BetaClipping";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/login" />
  },
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/clippingx",
    element: <IsLoginRouter path="/clipping" element={<LayoutComponent><ClippingPage /></LayoutComponent>} />,
  },
  {
    path: "/clipping",
    element: <IsLoginRouter path="/clipping" element={<LayoutComponent><BetaClippingPage /></LayoutComponent>} />,
  },
  {
    path: "/clipping/add",
    element: <IsLoginRouter path="/clipping/add" element={<LayoutComponent><UploadClippingPage /></LayoutComponent>} />,
  },
  {
    path: "/users",
    element: <IsLoginRouter path="/users" element={<LayoutComponent><UserManageMentPage /></LayoutComponent>} />,
  },
  {
    path: "/project",
    element: <IsLoginRouter path="/project" element={<LayoutComponent><ProjectPage /></LayoutComponent>} />,
  },
  {
    path: "/media",
    element: <IsLoginRouter path="/media" element={<LayoutComponent><MediaManageMentPage /></LayoutComponent>} />,
  },
  {
    path: "/company",
    element: <IsLoginRouter path="/company" element={<LayoutComponent><CompanyPage /></LayoutComponent>} />,
  },
  {
    path: "/viewClippingPdf/:id",
    element: <IsLoginRouter path="/pdf" element={<LayoutComponent><ViewClippingPdfPage /></LayoutComponent>} />,
  },
  {
    path: "/viewClipping/:id",
    element: <IsLoginRouter path="/viewClipping" element={<LayoutComponent><ViewClippingPage /></LayoutComponent>} />,
  },
]);

export default Router;
