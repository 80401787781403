import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  fetchImageByUrl,
  getClippingService,
  fetchPdf2Img,
} from "../../services/clippingService";
import { alertError, alertSuccess } from "../../utils/notification";
import DataTableComponent from "../../components/TableComponent";

import SearchIcon from "@mui/icons-material/Search";
import { formatDate } from "../../utils/utils";
import MasterFilterComponent from "./masterFilterComponent";
import PdfGenerator from "../../components/pdf/PdfComponent";

import JSZip from "jszip";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

interface ClippingData {
  id: number;
  title: string;
  publishing: string;
  ad_value: string;
  pr_value: string;
  pr_score: string;
  media_score: string;
  pr_credit: string;
  sourceUrl: string;
  createdAt: string;
  updatedAt: string;
  select_date: string;
  project: Project;
  category: any;
  imgList: ImgList[];
}

interface Project {}

// interface Category {
//     id: number;
//     title: string;
//     category: MediaType;
// }

interface MediaType {
  id: number;
  title: string;
}

interface ImgList {
  img_sources: any;
}

interface CheckedItems {
  [key: number]: boolean;
}

const ClippingPage: React.FC = () => {
  const [rows, setRows] = useState<ClippingData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [itemListLength, setItemListLength] = useState(0);
  const [exportRows, setExportRows] = useState<ClippingData[]>([]);

  const [oepnExportModal, setOpenExportModal] = useState(false);
  const handleClose = () => setOpenExportModal(false);
  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  const columns = [
    {
      name: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <input
            type="checkbox"
            style={{ transform: "scale(1.2)" }}
            checked={allChecked}
            onChange={() => handleAllCheckboxChange()}
          />
        </div>
      ),
      cell: (row: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <input
            type="checkbox"
            checked={row.checked}
            onChange={() => handleCheckboxChange(row.id)}
            style={{ transform: "scale(1.2)" }}
          />
        </div>
      ),
      width: "50px",
    },
    {
      name: "Media",
      selector: (row: any) => (
        <div className="d-flex gap-1 align-items-center">
          {row.category.imgSource ? (
            <img
              src={row.category.imgSource.imgUrl}
              alt=""
              className="rounded-circle"
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            <img
              src="./icon/no-img.png"
              alt=""
              className="rounded-circle"
              style={{ width: "40px", height: "40px" }}
            />
          )}
          <b>{row.category.title}</b>
        </div>
      ),
    },
    {
      name: "Topic/Headline",
      selector: (row: any) => row.title,
    },
    {
      name: "Media Type",
      selector: (row: any) => row.category.category.title,
    },
    {
      name: "Client/Company",
      selector: (row: any) => row.company.title,
    },
    {
      name: "Date",
      selector: (row: any) => formatDate(row.select_date),
    },
    {
      name: "Ads Value",
      selector: (row: any) => row.ad_value,
    },
    {
      name: "PR Value",
      selector: (row: any) => row.pr_value,
    },
    {
      name: "PR Score",
      selector: (row: any) => row.pr_score,
    },
    {
      name: "PR Credit",
      selector: (row: any) => row.pr_credit,
    },
    {
      name: "Sentiment",
      selector: (row: any) => <div className="rounded-pill bg-white px-2 py-1 text-black">{row.sentiment_score}</div>,
    },
    {
      name: "view",
      selector: (row: any) => (
        <div className="d-flex flex-column gap-1">
          <Button
            onClick={() => {
              window.location.href = `/viewclipping/${row.id}`;
            }}
            variant="contained"
            color="primary"
          >
            View
          </Button>
          <Button
            onClick={() => {
              window.location.href = `/viewClippingPdf/${row.id}`;
            }}
            variant="contained"
            color="primary"
          >
            PDF
          </Button>
        </div>
      ),
    },
  ];

  const onRemoveSelectedClipping = async (clippingId: number) => {
    const itemList = localStorage.getItem("itemList");
    let localItemList = itemList ? JSON.parse(itemList) : [];

    // remove id in list
    localItemList = localItemList.filter((item: any) => item !== clippingId);
    // update on local storage
    localStorage.setItem("itemList", JSON.stringify(localItemList));

    console.log(localItemList);

    const updateData = exportRows.filter((item: any) => item.id !== clippingId);
    setExportRows(updateData);

    await featchMainData();
  };

  const exportColumns = [
    {
      name: "Action",
      selector: (row: any) => (
        <Button
          onClick={() => onRemoveSelectedClipping(row.id)}
          variant="contained"
          color="error"
        >
          Del
        </Button>
      ),
    },
    {
      name: "Media",
      selector: (row: any) => (
        <div className="d-flex gap-1 align-items-center">
          {row.category.imgSource ? (
            <img
              src={row.category.imgSource.imgUrl}
              alt=""
              className="rounded-circle"
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            <img
              src="./icon/no-img.png"
              alt=""
              className="rounded-circle"
              style={{ width: "40px", height: "40px" }}
            />
          )}
          <b>{row.category.title}</b>
        </div>
      ),
    },
    {
      name: "Topic/Headline",
      selector: (row: any) => row.title,
    },
    {
      name: "Media Type",
      selector: (row: any) => row.category.category.title,
    },
    {
      name: "Client/Company",
      selector: (row: any) => row.company.title,
    },
    {
      name: "Date",
      selector: (row: any) => formatDate(row.select_date),
    },
    {
      name: "Ads Value",
      selector: (row: any) => row.ad_value,
    },
    {
      name: "PR Value",
      selector: (row: any) => row.pr_value,
    },
    {
      name: "PR Score",
      selector: (row: any) => row.pr_score,
    },
    {
      name: "PR Credit",
      selector: (row: any) => row.pr_credit,
    },
    {
      name: "Sentiment",
      selector: (row: any) => <div className="rounded-pill bg-danger">{row.sentiment_score}</div>,
    },
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    return row.title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  useEffect(() => {
    const itemList = localStorage.getItem("itemList");
    const localItemList = itemList ? JSON.parse(itemList) : [];
    setItemListLength(localItemList.length);

    const fetchData = async () => {
      try {
        const data = await getClippingService({
          company: 0,
          date_range: [null, null],
          project_id: 0,
          sub_project: 0,
          clipping_id_list: [],
        });

        // Assign the checked field based on localItemList
        const updatedData = data.map((item: any) => ({
          ...item,
          checked: localItemList.includes(item.id),
        }));

        setRows(updatedData);
      } catch (err: any) {
        alertError("Service Fetch Media Error");
      }
    };
    fetchData();
  }, []);

  const featchMainData = async () => {
    const itemList = localStorage.getItem("itemList");
    const localItemList = itemList ? JSON.parse(itemList) : [];
    setItemListLength(localItemList.length);

    try {
      const data = await getClippingService({
        company: 0,
        date_range: [null, null],
        project_id: 0,
        sub_project: 0,
        clipping_id_list: [],
      });

      // Assign the checked field based on localItemList
      const updatedData = data.map((item: any) => ({
        ...item,
        checked: localItemList.includes(item.id),
      }));

      setRows(updatedData);
    } catch (err: any) {
      alertError("Service Fetch Media Error");
    }
  };

  const handleOpen = async () => {
    await fetchSelectedClipping();
    setOpenExportModal(true);
  };

  const fetchSelectedClipping = async () => {
    const itemList = localStorage.getItem("itemList");
    const localItemList = itemList ? JSON.parse(itemList) : [];

    if (localItemList.length > 0) {
      const data = await getClippingService({
        company: 0,
        date_range: [null, null],
        project_id: 0,
        sub_project: 0,
        clipping_id_list: localItemList,
      });
      setExportRows(data);
    } else {
      setExportRows([]);
    }
  };

  const handleFormSubmit = async (formData: any) => {
    console.log("Form data received in parent:", formData);
    const data = await getClippingService({
      ...formData,
      clipping_id_list: [],
    });

    // Retrieve local item list from localStorage
    const itemList = localStorage.getItem("itemList");
    const localItemList = itemList ? JSON.parse(itemList) : [];
    setItemListLength(localItemList.length);

    // Assign the checked field based on localItemList
    const updatedData = data.map((item: any) => ({
      ...item,
      checked: localItemList.includes(item.id),
    }));

    setRows(updatedData);
  };

  const handleAllCheckboxChange = () => {
    setAllChecked(!allChecked);
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) => ({
        ...row,
        checked: !allChecked,
      }));

      // Update localStorage
      // const storedItemsString = localStorage.getItem('itemList');
      // const existingCheckedItems = storedItemsString ? JSON.parse(storedItemsString) : [];
      const newCheckedItems = updatedRows
        .filter((row) => row.checked)
        .map((row) => row.id);
      // const combinedCheckedItems = Array.from(new Set([...existingCheckedItems, ...newCheckedItems]));
      setItemListLength(newCheckedItems.length);
      localStorage.setItem("itemList", JSON.stringify(newCheckedItems));

      return updatedRows;
    });
  };

  const handleCheckboxChange = (id: number) => {
    // Update the checked state of the row
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row: any) => {
        if (row.id === id) {
          return { ...row, checked: !row.checked };
        }
        return row;
      });

      // Update localStorage
      const checkedItems = updatedRows
        .filter((row) => row.checked)
        .map((row) => row.id);
      localStorage.setItem("itemList", JSON.stringify(checkedItems));

      setItemListLength(checkedItems.length);

      return updatedRows;
    });
  };

  const handleClearSeletedClipping = async () => {
    localStorage.removeItem("itemList");
    await featchMainData();
    await fetchSelectedClipping();
  };

  const downloadAllPDFs = async (exportType: string) => {
    try {
      setLoading(true);
      const zip = new JSZip();
      const imageBlobs = []; 
      // Generate each PDF and add it to the zip
      if (exportRows && exportRows.length > 0) {
        for (let i = 0; i < exportRows.length; i++) {
          const fetchedImgList = exportRows[i]?.imgList || [];
          let imgList = [];
          for (const index in fetchedImgList) {
            imgList.push(fetchedImgList[index]?.img_sources[0].img_source);
          }

          // get media
          let imgMedia = exportRows[i]?.category?.imgSource?.imgUrl
            ? exportRows[i].category.imgSource.imgUrl
            : "";

          // make payload
          const payload = {
            imgList: [
              {
                img_media: imgMedia,
                img_content: imgList,
              },
            ],
          };

          const imgInfo = await fetchImageByUrl(payload);

          const pdfContent = (
            <PdfGenerator imgList={imgInfo} data={exportRows[i]} />
          );
          const pdfBlob = await pdf(pdfContent).toBlob();
          zip.file(`clipping${i + 1}.pdf`, pdfBlob);
        }

        // Generate the zip file asynchronously
        let zipBlob = await zip.generateAsync({ type: 'blob' });

        if(exportType === 'img'){
            const formData = new FormData();
            formData.append('file', zipBlob, 'clippings.zip');
            let response = await fetchPdf2Img(formData);
            console.log(response)
            saveAs(response, 'images.zip');
        }else{
             // Save the zip file
            saveAs(zipBlob, 'clippings.zip');
        }

       
      }

      handleClose();
      alertSuccess("dowload successfully !");
    } catch (error) {
      console.log(error);
      alertError("service error !");
    } finally {
      setLoading(false);
    }
  };

  async function blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1] || '';
        resolve(base64String);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(blob);
    });
  }

  return (
    <div className="py-2">
      <div className="container-fluid card p-2">
        <div className="row">
          <MasterFilterComponent onSubmit={handleFormSubmit} />
        </div>
        <div className="d-flex gap-2">
          <b>Clipping List</b>
          <div
            onClick={handleOpen}
            className="position-relative"
            style={{ cursor: "pointer" }}
          >
            <img
              src="../icon/download.png"
              alt=""
              style={{ width: "25px", height: "25px" }}
            />
            {itemListLength > 0 && (
              <span
                className="badge bg-danger position-absolute"
                style={{ top: "-10px", left: "15px" }}
              >
                {itemListLength}
              </span>
            )}
          </div>
        </div>
        <div className="row mb-2 pt-2">
          <div className="col-6">
            <div className="position-relative">
              <SearchIcon
                className="position-absolute"
                style={{
                  top: "50%",
                  left: "20px",
                  transform: "translate(-50%, -50%)    ",
                }}
              />
              <input
                className="form-control h-100"
                type="text"
                placeholder="Search.."
                value={searchQuery}
                onChange={handleSearch}
                style={{ maxWidth: "50%", paddingLeft: "30px" }}
              />
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button
                onClick={() => {
                  window.location.href = "/clipping/add";
                }}
              >
                + ADD CLIPPING
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <DataTableComponent data={filteredRows} columns={columns} />
      </div>

      <Modal
        open={oepnExportModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{ onClick: (e) => e.stopPropagation() }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1200,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <DataTableComponent data={exportRows} columns={exportColumns} />
          <div className="d-flex gap-1">
            <Button
              disabled={loading}
              onClick={() => downloadAllPDFs("pdf")}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress /> : "PDF"}
            </Button>
            <Button
              disabled={loading}
              onClick={() => downloadAllPDFs("img")}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress /> : "IMG"}
            </Button>
            <Button
              onClick={handleClearSeletedClipping}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Clear
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ClippingPage;
