import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from "@mui/material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ImageUploadPreview from "../../components/UploadImgComponent";
import { alertError } from "../../utils/notification";
import { uploadFile } from "../../services/firebaseService";

interface CompanyProps {
    id: number;
    title: string;
    imgSource: string;
}

interface DialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: CompanyProps) => void;
    title: string;
    buttonText: string;
    isEditing?: boolean;
    Data?: any;
}

enum errorMessage {
    required = 'this field is required',
    max = 'the field is maximum for',
    min = 'the field is minimum for',
}

const ActionCompanyDialogComponent: React.FC<DialogProps> = ({ open, onClose, onSubmit, title, buttonText, isEditing, Data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm<CompanyProps>();
    const [onConfirm, setOnConfirm] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    // for img
    const [currentSelectedImg, setCurrentSelectedImg] = useState<any>();


    const handleFormSubmit: SubmitHandler<CompanyProps> = (data) => {
        setData(data);
        setOnConfirm(true);
    };

    const handleConfirm = async () => {
        setOnConfirm(false);
        try {

            let imgUrl;
            if (currentSelectedImg && currentSelectedImg !== Data?.imgSource?.imgUrl ) {
                imgUrl = await uploadFile(currentSelectedImg, 'P_COMPANY');
            }else{
                imgUrl = currentSelectedImg;
            }

            if (isEditing) {
                let id = Data.id
                onSubmit({ ...data, id, imgSource: imgUrl || ''  });
            } else {
                onSubmit({ ...data, imgSource: imgUrl || '' })
            }

        } catch (error) {
            console.log(error)
            alertError('Service Error')

        }finally{
            reset();
        }

        
    }
    return <>
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => { onClose(); reset(); }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>

                <ImageUploadPreview onSubmit={(data) => {
                    setCurrentSelectedImg(data);
                }}
                    Data={Data}
                    isEditing={isEditing}
                />

                {!isEditing ? <form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormControl className="py-2" fullWidth>
                        <TextField label='Company Name' focused {...register('title', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                        {errors.title && <FormHelperText className="text-danger" >{errors.title.message}</FormHelperText>}
                    </FormControl>

                    <DialogActions>
                        <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                        <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>{buttonText}</Button>
                    </DialogActions>
                </form> : <form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                    <FormControl className="py-2" fullWidth>
                        <TextField defaultValue={Data?.title} label='Title' focused {...register('title', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                        {errors.title && <FormHelperText className="text-danger" >{errors.title.message}</FormHelperText>}
                    </FormControl>

                    <DialogActions>
                        <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                        <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>{buttonText}</Button>
                    </DialogActions>
                </form>}
            </DialogContent>
        </Dialog>


        <Dialog open={onConfirm} >
            <DialogTitle>{isEditing ? 'Comfirm Update Company' : 'Comfirm Add Company'}</DialogTitle>
            <DialogContent>
                Are you sure to {isEditing ? 'update' : 'add'} new Company?
                <div className="w-100 d-flex gap-1 justify-content-center py-2">
                    <Button color="error" variant="contained" onClick={() => { setOnConfirm(false) }}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={() => { handleConfirm(); }} type="submit">Submit</Button>
                </div>
            </DialogContent>
        </Dialog>

    </>
}

export default ActionCompanyDialogComponent;