import React, { ReactNode, useEffect, useRef, useState } from "react";

//icon
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { StyledLinkMenu } from "./LayoutTools";

//css
import './CustomStyle.css';

//for check pathname
import { useLocation } from 'react-router-dom';
import logoImg from './NCM-Logo.webp'

interface LayoutComponentProps {
    children: ReactNode;
}

const iconMapping: Record<string, JSX.Element> = {
    'PeopleIcon': <PeopleIcon />,
    'FolderIcon': <FolderIcon />,
    'MenuBookIcon': <MenuBookIcon />,
    'NewspaperIcon': <NewspaperIcon />,
};

const menuList = [
    {
        title: 'Clipping',
        icon: 'MenuBookIcon',
        children: [
            // {
            //     title: 'News Clipping',
            //     pathname: '/clipping',
            // },
            {
                title: 'News Clipping',
                pathname: '/clipping',
            },
            {
                title: 'Add News Clipping',
                pathname: '/clipping/add',
            },
        ]
    },
    {
        title: 'Users',
        icon: 'PeopleIcon',
        children: [
            {
                title: 'User Management',
                pathname: '/users',
            },
        ]
    },
    {
        title: 'Project',
        icon: 'FolderIcon',
        children: [
            {
                title: 'Client Management',
                pathname: '/company',
            },
            {
                title: 'Project Management',
                pathname: '/project',
            },
        ]
    },
    {
        title: 'News',
        icon: 'NewspaperIcon',
        children: [
            {
                title: 'Media Management',
                pathname: '/media',
            },
        ]
    },
]

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children }) => {

    const [toggleSidbar, setToggleSidbar] = useState(false);
    const location = useLocation();
    const projectName = useRef('NCM PROJECT');
    const [currentTitle, setCurrentTitle] = useState('');
    const CurrentPathname = location.pathname;

    const sidebarRef = useRef<HTMLDivElement>(null);

    // for responsive
    const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 768);

    const toggleSidebar = () => {
        setToggleSidbar(!toggleSidbar);
    };


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
        };

        window.addEventListener('resize', handleResize);

        // Log the initial size
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
                setToggleSidbar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const setCurrentTitleFromPathname = () => {
            for (const menuItem of menuList) {
                for (const child of menuItem.children) {
                    if (child.pathname === location.pathname) {
                        setCurrentTitle(child.title);
                        return;
                    }
                }
            }
        };
        setCurrentTitleFromPathname();

    }, [location.pathname]);

    const handleLogout = () => {
        window.localStorage.clear();
        window.location.href = '/login'
    }



    return (
        <div className="vh-100 vw-100 d-flex">

            {/* header */}
            <header className="bg-light position-fixed top-0 w-100 p-3 HeaderBoxShadow" style={{ left: `${(toggleSidbar || !isMobile) ? 250 : 0}px`, zIndex: 9999 }}>
                <div className="d-flex align-items-center">
                    {
                        isMobile && <button onClick={toggleSidebar} className="bg-transparent border-0"><MenuIcon /></button>
                    }

                    <h3>{currentTitle}</h3>
                </div>
            </header>

            {/* sidebar menu */}
            {(toggleSidbar || !isMobile) &&
                <div ref={sidebarRef} className="h-100 d-flex flex-column align-items-center position-fixed left-0 top-0" style={{ width: `${(toggleSidbar || !isMobile) ? 250 : 70}px`, background: 'var(--primary-color)',zIndex: 100 }}>

                    {/* section brand */}
                    <div className="py-4 d-flex align-items-center">
                        <div className="p-2">
                            <img className="img-fluid" src={logoImg} alt="" />
                        </div>
                        {toggleSidbar &&
                            <div className="mx-2">
                                <b>{projectName.current}</b>
                            </div>
                        }
                    </div>

                    {/* section menu */}
                    <div className="w-100 mt-5">
                        {menuList.map((menuItem, index) => (
                            <Accordion
                                key={index} className="m-0 w-100 bg-transparent shadow-none" defaultExpanded={(toggleSidbar || !isMobile) ? true : false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon className="text-white m-0" />}>
                                    <b className="text-white d-flex align-items-center gap-2">{iconMapping[menuItem.icon]} {menuItem.title}</b>
                                </AccordionSummary>
                                <AccordionDetails className="pb-0">
                                    {menuItem.children && menuItem.children.map((child, idx) => (
                                        <div key={idx} className="ps-4 d-flex gap-2 align-items-center mb-2">
                                            <div className="rounded-circle bg-white" style={{ width : '5px', height : '5px'}}></div> <StyledLinkMenu href={child.pathname} style={{ color: CurrentPathname === child.pathname ? "#fff" : "#ffffffb3" , fontWeight : '400' }}>{child.title}</StyledLinkMenu>
                                        </div>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                         <div className="d-flex justify-content-center p-2">
                            <Button onClick={handleLogout} variant="contained" color="error">Logout</Button>
                        </div>
                    </div>
                </div>

            }

            {/* main content */}
            <div className="flex-grow-1 overflow-auto" style={{ paddingTop: '75px',background: '#f5f8f9' , paddingLeft : `${!isMobile ? 250 : 0}px` }}>
                <div className="w-100 h-100 p-2">
                    {children}
                </div>
            </div>


        </div>
    );
};

export default LayoutComponent;


//style

