import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  Modernteer: {
    backgroundColor: '#f1f1f1',
    padding: 5,
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    flexBasis: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    paddingLeft: 10,
    flexBasis: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  footer: {
    flexBasis: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  contentText: {
    fontSize: 12,
    color: '#000000',
  },
  footerText: {
    fontSize: 12,
    color: '#000000',
    marginBottom: 5,
  },
  title: {
    fontSize: 18,
    color: '#0a768a'
  },
  logoImg: {
    width: 120, 
    height: 80, 
},
});

interface footerPdfProps {
    currentPage : number;
    total : number;
}

const getImageBlob = async (imagePath : string) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

const FooterPDF:React.FC<footerPdfProps> = ({ currentPage , total}) => (
      <View style={styles.Modernteer}>
        <View style={styles.logo}>
            <Image style={styles.logoImg} src="../icon/brand.png" />
        </View>
        <View style={styles.content}>
          <Text style={styles.title}>Modernteer Co.,Ltd.</Text>
          <Text style={styles.contentText}>Tel. 02 077 1767 | hello@modernteer.com | www.modernteer.com</Text>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>รหัส 2024060001</Text>
          <Text style={styles.footerText}>Page {currentPage}/{total}</Text>
        </View>
      </View>
);

export default FooterPDF;
