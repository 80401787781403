import { Box, Button, DialogActions, DialogTitle, Modal } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";


interface ActionAssignmentProjectProps {
    open : boolean;
    onClose : () => void;
    onSubmit: (data: any) => void;
    Data? : any;
    UserList? : any;
}

const ActionProjectAssignmentComponent : React.FC<ActionAssignmentProjectProps> = ({ open, onClose, onSubmit, Data ,UserList}) => {

    const [selectedUsers , setSelectedUsers] = useState<any[]>([]);

    const onSelect = (selectedList: any) => {
        setSelectedUsers(selectedList);
    }
    const onRemove = (selectedList: any) => {
        setSelectedUsers(selectedList);
    }

    const handleSubmit = () => {
        onSubmit(selectedUsers);
        setSelectedUsers([]);
    }

    return <>

            <Modal
                open={open}
                onClose={()=> {onClose(); setSelectedUsers([]);}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 , borderRadius : '5px' }}>
                    <DialogTitle className="p-0">Assignment</DialogTitle>
                    <Box sx={{ mt: 2 }}>
                            <Multiselect
                                className="w-100"
                                options={UserList}
                                selectedValues={Data}
                                onSelect={onSelect}
                                onRemove={onRemove} 
                                displayValue="fullName"
                            />
                            <DialogActions>
                                <Button onClick={() => { onClose(); setSelectedUsers([]);}}>Cancel</Button>
                                <Button color="primary" variant="contained" type="submit" onClick={()=> handleSubmit() }>Submit</Button>
                            </DialogActions>
                    </Box>
                </Box>
            </Modal>
            
    </>
}

export default ActionProjectAssignmentComponent;