import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { alertError } from "../../utils/notification";
import {
    getAllProjectWithCompanyService,
    getCompanyService,
} from "../../services/projectService";
import { DateRangePicker } from "rsuite";
import { format } from 'date-fns';

interface ProjectOption {
    id: number;
    title: string;
    sub_project?: SubProjectOption[];
}

interface SubProjectOption {
    id: number;
    title: string;
}

interface CompanyOption {
    id: number;
    title: string;
}

interface MasterFilterComponentProps {
    onSubmit: (formData: any) => void;
}


interface FormData {
    company_id: number;
    date_range: [string, string] | [null , null];
    project_id: number;
    sub_project: number;
  }
  
  const initialState: FormData = {
    company_id: 0,
    date_range: [null , null],
    project_id: 0,
    sub_project: 0,
  };

const MasterFilterComponent: React.FC<MasterFilterComponentProps> = ({ onSubmit }) => {
    const [projectOption, setProjectOption] = useState<ProjectOption[] | undefined>();
    const [companyOption, setCompanyOption] = useState<CompanyOption[] | undefined>();
    const [selectedCompany, setSelectedCompany] = useState<CompanyOption | null>(null);
    const [formData, setFormData] = useState<FormData>(initialState);

    // for company
    const [selectedProject, setSelectedProject] = useState<ProjectOption | null>(
        null
    );
    const [selectedSubProject, setSelectedSubProject] =
        useState<SubProjectOption | null>(null);
    const [isSubProject, setIsSupProject] = useState(false);

    const handleCompanyChange = (companyId: number) => {
        const company = companyOption?.find((c) => c.id === companyId) || null;
        setSelectedCompany(company);

        setFormData({
            ...formData,
            company_id: companyId,
          });
    };

    const handleDateRangeChange = (value : any) => {
        if (value) {
            const [startDate, endDate] = value;
            setFormData({
              ...formData,
              date_range: [format(startDate, 'dd-MM-yyyy'), format(endDate, 'dd-MM-yyyy')],
            });
          } else {
            setFormData({
              ...formData,
              date_range: [null , null],
            });
          }
      };

    const getOptionLabel = (option: CompanyOption) => option.title;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const companies = await getCompanyService();
                const projects = await getAllProjectWithCompanyService();
                setCompanyOption(companies);
                setProjectOption(projects);
            } catch (err: any) {
                alertError("Service Error");
            }
        };
        fetchData();
    }, []);

    // ------ for get project id -----
    const handleProjectChange = (projectId: number) => {
        // reset
        setIsSupProject(false);
        setSelectedSubProject(null);

        const project = projectOption?.find((p) => p.id === projectId) || null;
        setSelectedProject(project);

        if (
            project &&
            Array.isArray(project?.sub_project) &&
            project?.sub_project?.length > 0
        ) {
            setIsSupProject(true);
        } else {
            setIsSupProject(false);
        }

        setFormData({
            ...formData,
            project_id: projectId,
          });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onSubmit(formData); 
    };

    return (
        <div className="py-2 w-100">
            <b>Filter By</b>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-3">
                        <FormControl className="my-2" fullWidth size="small">
                            <Autocomplete
                                size="small"
                                disablePortal
                                options={companyOption || []}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <TextField {...params} label="Company *" />
                                )}
                                onChange={(event, newInputValue) => {
                                    handleCompanyChange(newInputValue?.id || 0);
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="col-3">
                        <FormControl className="py-2" fullWidth size="small">
                            <DateRangePicker
                                format="dd/MM/yyyy"
                                character=" - "
                                placeholder="Select Date range"
                                size="lg"
                                onChange={handleDateRangeChange}
                            />
                        </FormControl>
                    </div>
                    <div className="col-3">
                        <FormControl className="my-2" fullWidth size="small">
                            <Autocomplete
                                size="small"
                                disablePortal
                                options={projectOption || []}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <TextField {...params} label="Project *" />
                                )}
                                onChange={(event, newInputValue) => {
                                    handleProjectChange(newInputValue?.id || 0);
                                }}
                                value={selectedProject || null}
                                disabled={selectedCompany ? false : true}
                            />
                        </FormControl>
                    </div>
                    <div className="col-3">
                        {isSubProject && selectedProject && selectedProject.sub_project ? (
                            <FormControl className="my-2" fullWidth size="small">
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    options={selectedProject.sub_project || []}
                                    getOptionLabel={getOptionLabel}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Sub Project *" />
                                    )}
                                    onChange={(event, newInputValue) => {
                                        setSelectedSubProject(
                                            selectedProject.sub_project!.find(
                                                (sub) => sub.id === Number(newInputValue?.id || 0)
                                            ) || null
                                        );

                                        setFormData({
                                            ...formData,
                                            sub_project: newInputValue?.id || 0,
                                          });
                                    }}
                                    value={selectedSubProject || null}
                                />
                            </FormControl>
                        ) : (
                            <FormControl className="my-2" fullWidth size="small">
                                <Autocomplete
                                    size="small"
                                    disablePortal
                                    options={[]}
                                    getOptionLabel={getOptionLabel}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Sub Project *" />
                                    )}
                                    disabled
                                    value={selectedSubProject || null}
                                />
                            </FormControl>
                        )}
                    </div>
                </div>
                <div className="col-12">
                    <Button className="w-100" type="submit" variant="contained" color="primary">
                        Search
                    </Button>
                </div>
                <hr />

            </form>
        </div>
    );
};

export default MasterFilterComponent;
