import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuthData } from "./AuthDataContext";

interface PermissionRouterProps {
  path: string;
  element: ReactElement;
  requiredRole?: string[];
}

const IsLoginRouter: React.FC<PermissionRouterProps> = ({ path, element: Element, requiredRole = [] }) => {
  const accessToken = localStorage.getItem("access_token");
  const { userData } = useAuthData();

  if (!accessToken && path !== "/login") {
    return <Navigate to="/login" />;
  }

  if (requiredRole.length === 0){
    return <>{Element}</>; 
  }

  if (requiredRole?.length > 0 && userData && requiredRole.includes(userData.role.id)) {
    return <>{Element}</>;
  }

  return <Navigate to="/unauthorized" />;
};

export default IsLoginRouter;
