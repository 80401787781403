import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';

const uploadFile = async (file : File , pathName: string ): Promise<string | null> => {
    if (!file) return null;

    const storageRef = ref(storage, `files/${pathName}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
        await uploadTask; // Wait for the upload to complete
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    } catch (error) {
        console.error('Error uploading file or getting download URL:', error);
        return null;
    }
};

export { uploadFile };
