import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { Button } from "@mui/material";

import { useEffect, useRef } from "react";
import './index.css'

import brandIMG from './brand.png'
import mailIcon from './icon-mail.jpg'
import internetIcon from './icon-internet.jpg'
import telIcon from './icon-tel.jpg'
import footerImg from './footer.jpg'

interface componentProps {
    data: any;
    dowloanding: boolean;
    type: string;
}

const ExportFunction: React.FC<componentProps> = ({ data, dowloanding, type }) => {
    const containerRefs = useRef<{ [key: string]: (HTMLDivElement | null)[] }>({});

    const downloadAsPDFs = async () => {
        const zip = new JSZip();
        let pdfBlobList = [];

        for (const projectData of data) {
            const pdf = new jsPDF('p', 'mm', 'a4');

            for (const [index, item] of projectData.imgList.entries()) {
                const container = containerRefs.current[projectData.id][index];

                // Check if container is null or not an HTML element
                if (!container || !(container instanceof HTMLElement)) {
                    continue; // Skip to the next iteration if container is null or not an HTMLElement
                }

                const canvas = await html2canvas(container as HTMLElement , {
                    scale: 2,
                });
                const imgData = canvas.toDataURL('image/png');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfRatio = imgProps.width / imgProps.height;
                const imgWidth = pdfWidth;
                const imgHeight = imgWidth / pdfRatio;

                if (index > 0) {
                    pdf.addPage();
                }
                // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
            }

            // Generate the PDF as a Blob and add to the zip
            // const pdfBlob = pdf.output('blob');
            // zip.file(`Project_${projectData.id}.pdf`, pdfBlob);

            const pdfBlob = pdf.output('blob');
            pdfBlobList.push({ id: projectData.id, blob: pdfBlob });
        }

        // Generate the zip file and trigger download
        // const zipBlob = await zip.generateAsync({ type: 'blob' });
        // saveAs(zipBlob, 'projects.zip');

        if (pdfBlobList.length === 1) {
            // If there's only one PDF, download it directly
            const singlePdfBlob = pdfBlobList[0].blob;

            const date = new Date();
            const year = date.getFullYear().toString().slice(-2);  // ปีปัจจุบัน
            const month = ('0' + (date.getMonth() + 1)).slice(-2);  // เดือนปัจจุบัน (เติม 0 ด้านหน้า ถ้าจำนวนหลักน้อยกว่า 2)
            const sequence = pdfBlobList[0].id.toString().padStart(4, '0');

            const fileCode = `C${year}${month}${sequence}.pdf`;

            saveAs(singlePdfBlob, fileCode);
        } else if (pdfBlobList.length > 1) {
            // If there's more than one PDF, add them to the zip
            for (const { id, blob } of pdfBlobList) {

                const date = new Date();
                const year = date.getFullYear();  // ปีปัจจุบัน
                const month = ('0' + (date.getMonth() + 1)).slice(-2);  // เดือนปัจจุบัน (เติม 0 ด้านหน้า ถ้าจำนวนหลักน้อยกว่า 2)
                const sequence = id.toString().padStart(4, '0');

                const fileCode = `C${year}${month}${sequence}.pdf`;

                zip.file(fileCode, blob);
            }
    
            // Generate the zip file and trigger download
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, 'projects.zip');
        }

    };

    const downloadAsImages = async () => {
        const zip = new JSZip();

        for (const projectData of data) {
            const projectFolder = zip.folder(`project${projectData.id}`);

            if (!projectFolder) {
                // Handle case where projectFolder is null (unlikely for JSZip, but for completeness)
                console.error(`Failed to create folder for project ${projectData.id}`);
                continue;
            }

            for (const [index, item] of projectData.imgList.entries()) {
                const container = containerRefs.current[projectData.id]?.[index];

                // Check if container is null or not an HTML element
                if (!container || !(container instanceof HTMLElement)) {
                    console.error(`Container for project ${projectData.id} at index ${index} is invalid.`);
                    continue; // Skip to the next iteration if container is null or not an HTMLElement
                }

                try {
                    const canvas = await html2canvas(container, { 
                        scale: 2,
                        allowTaint: true 
                    });
                    const imgData = canvas.toDataURL('image/png');

                    // Convert data URL to a Blob
                    // const response = await fetch(imgData);
                    // const blob = await response.blob();
                    const blob = await fetch(imgData).then(res => res.blob());

                    const date = new Date();
                    const year = date.getFullYear();  // ปีปัจจุบัน
                    const month = ('0' + (date.getMonth() + 1)).slice(-2);  // เดือนปัจจุบัน (เติม 0 ด้านหน้า ถ้าจำนวนหลักน้อยกว่า 2)
                    const sequence = projectData.id.toString().padStart(4, '0');

                    const fileCode = `C${year}${month}${sequence}-${index+1}.png`;

                    // Add image to the project folder in the zip
                    projectFolder.file(fileCode, blob);
                } catch (error) {
                    console.error(`Error processing image for project ${projectData.id} at index ${index}:`, error);
                }
            }
        }

        // Generate the zip file and trigger download
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'projects.zip');
    };

    const convertToThaiDate = (dateString: string) => {
        const days = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'];
        const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];

        const date = new Date(dateString);
        const day = days[date.getDay()];
        const dayOfMonth = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear() + 543;

        return `${day} ${dayOfMonth} ${month} ${year}`;
    }

    const formatNumberWithCommas = (number : number) => {
        // ตรวจสอบว่าเป็นตัวเลขหรือไม่
        if (isNaN(number)) return "";
      
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }


    useEffect(() => {

        // console.log( "data" , data)
        if (dowloanding) {
            if (type === 'img') {
                downloadAsImages();
            } else if (type === 'pdf') {
                downloadAsPDFs();
            }
        }
    }, [dowloanding])

    return (
        <>
            <div className="offscreen">
                {data.map((projectData: any, projectIndex: number) => (
                    <div key={projectIndex} className="project-container">
                        {projectData?.imgList.map((item: any, index: number) => (
                            <div
                                key={index}
                                ref={(el) => {
                                    if (!containerRefs.current[projectData.id]) {
                                        containerRefs.current[projectData.id] = [];
                                    }
                                    containerRefs.current[projectData.id][index] = el;
                                }}
                                className="a4-container d-flex flex-column justify-content-between"
                            >
                                <div className="d-flex header-box">
                                    <div className="header-box-1 border-right d-flex flex-column" style={{fontSize : '12px'}}>
                                        {
                                            projectData?.imgMedia ? <div className="d-flex h-75 w-100">
                                                <img style={{ width: "100%", height: "100%" }} src={`data:${projectData.imgMedia[0].img_content[0].contentType};base64,${projectData.imgMedia[0].img_content[0].imgBase64}`} alt="" />
                                            </div> : <div>
                                                {projectData?.category?.title}
                                            </div>
                                        }

                                        {projectData?.category?.category?.title === 'Print' && <div>Circulation: <small>{formatNumberWithCommas(projectData?.category?.print_count_newspaper) || 0}</small></div>}
                                        {projectData?.category?.category?.title === 'Online' && <div>Site Visitor Per Month: <small>{formatNumberWithCommas(data?.category?.site_visitor_per_month) || 0}</small></div>}
                                    </div>
                                    <div className="header-box-2 p-2 d-flex align-items-center">
                                        <div style={{ fontSize : "14px"}}>
                                            <div><b>Date : </b> <small>{convertToThaiDate(projectData?.select_date)}</small></div>
                                            <div className={"d-flex gap-4 align-items-center flex-wrap"}>
                                                { projectData?.category?.category?.title === 'Print' && <div><b>Clip : </b> <small>สี่สี</small> </div>}
                                                { projectData?.category?.category?.title === 'Print' && <div><b>Col.Inch : </b> <small>{projectData.print_type === 'color' ? formatNumberWithCommas(projectData?.category?.color_newspaper) : formatNumberWithCommas(projectData?.category?.bw_newspaper) }</small> </div>}
                                                { ["TV", "Radio"].includes(projectData?.category?.category?.title) && <div><b>Publishing : </b> <small>{formatNumberWithCommas(projectData?.publishing)} นาที</small> </div>}
                                                <div><b>AD Value : </b> <small>{formatNumberWithCommas(projectData?.ad_value)}</small> </div>
                                                <div><b>PR Value : </b> <small>{formatNumberWithCommas(projectData?.pr_value)}</small> </div>
                                            </div>
                                            <div className="d-flex gap-4">
                                                <div><b>PR Potential | PR Score : </b> <small>{projectData?.pr_score}</small></div>
                                                <div><b>Sentiment : </b> <small>{projectData?.sentiment_score}</small></div>
                                            </div>
                                            <div><b>Headline : </b> <small>{projectData.title}</small></div>
                                        </div>
                                    </div>
                                    {/* <div className="header-box-3 d-flex flex-column">
                                        <div className="custom-border-bottom d-flex align-items-center justify-content-center" style={{ background : '#05768b' , color : '#fff' , height : '80px'}}>PR Potential</div>
                                        <div className="h-100">
                                            <div className="custom-border-bottom h-50 d-flex">
                                                <div className="border-right w-50 d-flex align-items-center justify-content-center"><small>PR Score</small></div>
                                                <div className="w-50 d-flex align-items-center justify-content-center"><small>Sentiment</small></div>
                                            </div>
                                            <div className="h-50 d-flex">
                                                <div className="border-right w-50 d-flex align-items-center justify-content-center"><small>{projectData?.pr_score}</small></div>
                                                <div className="w-50 d-flex align-items-center justify-content-center"><small className="badge" style={{ background : "#00fe00" , color : "#000"}}>{projectData?.sentiment_score}</small></div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="w-100 d-flex align-items-center justify-content-center" style={{padding : '20px'}}>
                                    <img className="img-fluid" src={`data:${item.img_content[0].contentType};base64,${item.img_content[0].imgBase64}`} alt="" />
                                </div>
                                {/* <div className="w-100 text-center rounded d-flex justify-content-between p-2" style={{ background : "#f1f1f1" , height : "100px"}}>
                                        <div className="d-flex align-items-center justify-content-center w-25" >
                                            <img style={{ width : '180px' , height : '100px'}} src={brandIMG} alt="" />
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center h-100">
                                            <h5 style={{ color : '#0a768a'}}>Modernteer Co.,Ltd.</h5>
                                            <small>Tel. 02 077 1767 | hello@modernteer.com | www.modernteer.com</small>
                                        </div>
                                        <div className="d-flex flex-column h-100 align-items-end">
                                            <small>รหัส 000000001</small>
                                            <small>Page {projectIndex + 1}/{data.length}</small>
                                        </div>
                                </div> */}
                                <div className="w-100 text-center rounded d-flex p-2" style={{ height : "100px"}}>
                                        {/* <div className="d-flex align-items-center justify-content-center w-25" >
                                            <img style={{ width : '180px' , height : '100px'}} src={brandIMG} alt="" />
                                        </div>
                                        <div className="d-flex flex-column align-items-start justify-content-center h-100">
                                            <h5 style={{ color : '#0a768a'}}>Modernteer Co.,Ltd.</h5>
                                            <div className="d-flex gap-2">
                                                <div className="d-flex align-items-center"><img className="img-fulid" src={telIcon} style={{width : '35px' , height : '30px'}} alt="" /><small>Tel. 02 077 1767</small></div>
                                                <div className="d-flex align-items-center"><img className="img-fulid" src={mailIcon} style={{width : '35px' , height : '30px'}} alt="" /><small>hello@modernteer.com</small></div>
                                                <div className="d-flex align-items-center"><img className="img-fulid" src={internetIcon} style={{width : '35px' , height : '30px'}} alt="" /><small>www.modernteer.com</small></div>
                                            </div>
                                        </div> */}
                                        <img className="img-fluid" style={{ objectFit : 'cover'}} src={footerImg} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}

export default ExportFunction;