import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBVJ12rxu8Qm0O85lZxHAhmyzd7OIMEcpc",
  authDomain: "md-ncm.firebaseapp.com",
  projectId: "md-ncm",
  storageBucket: "md-ncm.appspot.com",
  messagingSenderId: "252819076645",
  appId: "1:252819076645:web:b7d3cd8e3f75767eab71cc"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
