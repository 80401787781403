import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';

interface ImageUploaderProps {
  onImagesSelected: (selectedImages: File[]) => void; 
}

const MultipleImgUploaderComponent: React.FC<ImageUploaderProps> = ({ onImagesSelected  }) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    console.log('Accepted Files:', acceptedFiles);
    console.log('Rejected Files:', rejectedFiles);

    const imagesArray = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);
    onImagesSelected([...selectedImages, ...imagesArray]);
  };

  const removeImage = (index: number) => {
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });

    const remainingImages = selectedImages.filter((_, i) => i !== index);
    onImagesSelected(remainingImages); 
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
  });

  return (
    <div className='card'>
       <Button {...getRootProps()}>
          Add Image
          <input {...getInputProps()} />
      </Button>
      <div>
        {selectedImages.map((image: any, index: number) => (
          <div key={index} style={{ display: 'inline-block', position: 'relative' }}>
            <img
              src={image.preview}
              alt={`preview-${index}`}
              style={{ maxWidth: '150px', maxHeight: '150px', margin: '10px' }}
            />
            <div
              onClick={() => removeImage(index)}
              style={{ position: 'absolute', top: '5px', right: '5px',cursor: 'pointer' }}
            >
              <img src="../icon/delete_img.png" alt="" style={{ width: '23px', height: '23px' }} />
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default MultipleImgUploaderComponent;
