import DataTable from "react-data-table-component";
import { deleteProjectService, editProjectService } from "../services/projectService";
import { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { alertError, alertSuccess } from "../utils/notification";
import ActionMainProjectComponent from "../pages/Project/actionMainProjectComponent";

interface SubProject {
    id: number;
    title: string;
}

interface ProjectData {
    id: number;
    title: string;
    sub_project: SubProject[];
}

interface Props {
    columns: any[];
    data: any[];
    currentPage?: string | undefined;
    onDataUpdate?: () => void;
}

const customStyles = {
    headRow: {
        style: {
            backgroundColor: 'rgba(242,246,247 , 1)',
            color: '#000',
            fontSize: '16px',
            border: 'none',
        }
    },
};

interface ExpandProps {
    data: ProjectData;
    refreshProjects?: () => void;
}


// Define the expandable component as a function component
const ExpandableComponent: React.FC<ExpandProps> = ({ data, refreshProjects = () => { } }) => {

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null)

    const [openActionModal, setOpenActionModal] = useState(false);
    const [currentSelectedProject, setCurrentSelectedProject] = useState<any>();

    const handleOpenDelete = (project_id: number) => {
        setProjectId(project_id);
        setOpenDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setOpenDeleteModal(false);
        setProjectId(null);
        setError(null);
    };

    const handleDelete = async () => {
        if (projectId !== null) {
            try {
                await deleteProjectService(projectId);
                refreshProjects();
                handleCloseDelete();

                alertSuccess('Delete Successfully')
            } catch (error: any) {
                setError('Failed to delete project.');
                alertSuccess('Service Fail')
            }
        }
    };

    const handleConfirmActionModal = async (data: any) => {
        setOpenActionModal(false);
        try {
            await editProjectService(data);
            refreshProjects();

            alertSuccess('Update success fully');
        } catch (error: any) {
            console.log(error)
            alertError('Service Error!');
        }
    };



    return (
        <>

            <div className="container">
                <div className="py-2 d-flex align-items-center gap-2">
                    <img src="./icon/main-folder.png" alt="" style={{ width: '20px', height: '20px' }} />
                    <b className="text-primary">{data.title}</b>
                </div>
                <ul>
                    {data.sub_project.map((subProject) => (
                        <li key={subProject.id} className="mb-1 d-flex gap-2 align-items-center">
                            <b>-</b>
                            <img src="./icon/folder.png" alt="" style={{ width: '20px', height: '20px' }} />
                            {subProject.title}
                            <div className="d-flex align-items-center gap-1">
                                <button className="btn btn-sm p-0" onClick={() => { setOpenActionModal(true); setCurrentSelectedProject(subProject); }}><img src="./icon/edit.png" alt="" style={{ width: '20px', height: '20px' }} /></button>
                                <button className="btn btn-sm p-0" onClick={() => handleOpenDelete(subProject.id)}><img src="./icon/delete.png" alt="" style={{ width: '20px', height: '20px' }} /></button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {/* Delete Modal */}
            <Modal
                open={openDeleteModal}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {error ? 'Error' : 'Confirm Deletion'}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {error ? error : 'Are you sure you want to delete ?'}
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        {!error && (
                            <>
                                <Button onClick={handleCloseDelete} sx={{ mr: 1 }}>Cancel</Button>
                                <Button color="error" onClick={handleDelete}>Delete</Button>
                            </>
                        )}
                        {error && (
                            <Button onClick={handleCloseDelete}>Close</Button>
                        )}
                    </Box>
                </Box>
            </Modal>

            <ActionMainProjectComponent
                open={openActionModal}
                onClose={() => { setOpenActionModal(false); setCurrentSelectedProject({}); }}
                onSubmit={(data) => {
                    handleConfirmActionModal(data)
                }}
                Data={currentSelectedProject}
            />

        </>
    )
}

const DataTableComponent: React.FC<Props> = ({ columns, data, currentPage = 'normal', onDataUpdate }) => {

    let tableContent;

    switch (currentPage) {

        case "projects":
            tableContent = (
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={({ data }) => (
                        <ExpandableComponent data={data} refreshProjects={onDataUpdate} />
                    )}
                    expandableRowDisabled={(row: ProjectData) => row.sub_project.length === 0}
                    customStyles={customStyles}
                />
            );

            break;

        default:
            tableContent = (
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                    conditionalRowStyles={[
                        {
                            when: row => row.checked,
                            style: {
                                backgroundColor: 'rgba(108,195,198,255)',
                                color: 'white',
                            },
                        },
                    ]}
                />
            );

    }

    return tableContent;


}


export default DataTableComponent;