import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ImageUploadPreview from "../../../components/UploadImgComponent";
import { uploadFile } from "../../../services/firebaseService";

interface AddUsersProps {
    firstName: string;
    lastName: string;
    roleId: roleType;
    status?: boolean;
}


enum roleType {
    PR = 'R04',
    HEAD_PR = 'R03',
    STAFF_ADMIN = 'R02',
}


interface DialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: AddUsersProps) => void;
    title: string;
    buttonText: string;
    isEditing?: boolean;
    userData?: any;
}

const roleOption = [
    { title: 'STAFF ADMIN', id: 'R02' },
    { title: 'HEAD PR', id: 'R03' },
    { title: 'PR', id: 'R04' },
]

enum errorMessage {
    required = 'this field is required',
    max = 'the field is maximum for',
    min = 'the field is minimum for',
}


const ActionUserDialogComponent: React.FC<DialogProps> = ({ open, onClose, onSubmit, title, buttonText, isEditing = false, userData }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm<AddUsersProps>();
    const [onConfirm, setOnConfirm] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const [status, setStatus] = useState<boolean>(userData?.status || false);
    const [currentSelectedImg, setCurrentSelectedImg] = useState<any>();


    const handleFormSubmit: SubmitHandler<AddUsersProps> = (data) => {
        setData(data);
        setOnConfirm(true);
    };

    const handleConfirm = async () => {

        setOnConfirm(false);

        let imgUrl;
        if (currentSelectedImg && currentSelectedImg !== userData?.imgSource?.imgUrl) {
            imgUrl = await uploadFile(currentSelectedImg, 'U_MEDIA_INFO');
        } else {
            imgUrl = currentSelectedImg;
        }

        if(isEditing){
            let id = userData.id
            onSubmit({ ...data, status , id , imgSource : imgUrl });
        }else{
            onSubmit({ ...data, imgSource : imgUrl })
        }

        reset();
    }

    useEffect(() => {
        setCurrentSelectedImg('');
        reset();
    }, [open]);



    return (
        <>
            <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => { onClose(); reset(); }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <ImageUploadPreview onSubmit={(data) => {
                        setCurrentSelectedImg(data);
                    }}
                        Data={userData}
                        isEditing={isEditing}
                    />
                    {!isEditing ? <form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormControl className="py-2" fullWidth>
                            <TextField label='Firstname' focused {...register('firstName', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.firstName && <FormHelperText className="text-danger" >{errors.firstName.message}</FormHelperText>}
                        </FormControl>

                        <FormControl className="py-2 mb-2" fullWidth>
                            <TextField label='Lastname' focused {...register('lastName', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.lastName && <FormHelperText className="text-danger" >{errors.lastName.message}</FormHelperText>}
                        </FormControl>

                        <FormControl className="py-2" fullWidth>
                            <InputLabel>Position</InputLabel>
                            <Select
                                defaultValue={"R04"}
                                label="Position"
                                {...register('roleId', { required: errorMessage.required })}
                            >
                                {roleOption.map((i: any) => <MenuItem key={i.id} value={i.id}>{i.title}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <DialogActions>
                            <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                            <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>{buttonText}</Button>
                        </DialogActions>
                    </form> : <form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormControl className="py-2" fullWidth>
                            <TextField defaultValue={userData?.firstName} label='Firstname' focused {...register('firstName', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.firstName && <FormHelperText className="text-danger" >{errors.firstName.message}</FormHelperText>}
                        </FormControl>

                        <FormControl className="py-2 mb-2" fullWidth>
                            <TextField defaultValue={userData?.lastName} label='Lastname' focused {...register('lastName', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.lastName && <FormHelperText className="text-danger" >{errors.lastName.message}</FormHelperText>}
                        </FormControl>
                        <FormControl className="py-2" fullWidth>
                            <InputLabel>Position</InputLabel>
                            <Select
                                defaultValue={userData?.role?.id}
                                label="Position"
                                {...register('roleId', { required: errorMessage.required })}
                            >
                                {roleOption.map((i: any) => <MenuItem key={i.id} value={i.id}>{i.title}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <div className="py-2 d-flex align-items-center">
                            <div>Status</div>
                            <Switch
                                defaultChecked={userData?.status}
                                sx={{ transform: "scale(1.2)" }}
                                onChange={(event) => setStatus(event.target.checked)}
                            />
                        </div>

                        <DialogActions>
                            <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                            <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>{buttonText}</Button>
                        </DialogActions>
                    </form>}
                </DialogContent>
            </Dialog>


            <Dialog open={onConfirm} >
                <DialogTitle>Comfirm Add User</DialogTitle>
                <DialogContent>
                    Are you sure to add new user?
                    <div className="w-100 d-flex gap-1 justify-content-center py-2">
                        <Button color="error" variant="contained" onClick={() => { setOnConfirm(false) }}>Cancel</Button>
                        <Button color="primary" variant="contained" onClick={() => { handleConfirm(); }} type="submit">Submit</Button>
                    </div>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default ActionUserDialogComponent;

