import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { alertError } from "../../utils/notification";
import { getMediaInfoService } from "../../services/newsService";
import MultipleImgUploaderComponent from "./multipleImgUploadComponent";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

interface AddClippingProp {
    title: string;
    category: number;
    project_id: number | string; // Change project_id type to accept string as initial value
    publishing: string;
    ad_value: string;
    pr_value: string;
    media_score: string;
    pr_score: string;
    pr_credit: string;
    sourceUrl: string;
    clippingImgs: string[];
    url: string;
    headline_score: string;
    content_score: string;
    sentiment_score: string;
    print_type: string;
    select_date: dayjs.Dayjs | string;
    clip_url: string;

}

enum errorMessage {
    required = 'this field is required',
    max = 'the field is maximum for',
    min = 'the field is minimum for',
}


interface ActionMediaInfoProps {
    onOpen: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
    isEditing?: boolean;
    Data?: any;
}

interface MediaOption {
    id: number;
    title: string;
    price: number;
    score: number;
    category: CategoryList;
    site_visitor_per_month: number;
    bw_newspaper: number;
    color_newspaper: number;
    print_count_newspaper: number;
}

interface CategoryList {
    id: number;
    title: string;
}
const sentimentOption = [
    {
        score: 20,
        title: 'Positive'
    },
    {
        score: 10,
        title: 'Neutral'
    },
    {
        score: 0,
        title: 'Negative'
    },
]
const scoreOptions = [1, 2, 3, 4, 5];
const printOptions = ['black-white', 'color']
const weightScore = [
    {
        title: 'media_score',
        weight: 40
    },
    {
        title: 'headline_score',
        weight: 10
    },
    {
        title: 'content_score',
        weight: 30
    },
    {
        title: 'sentiment_score',
        weight: 20
    },

]

const gradingScale = [
    { score: 100, grade: 'A++' },
    { score: 95, grade: 'A+' },
    { score: 90, grade: 'A' },
    { score: 85, grade: 'B+' },
    { score: 80, grade: 'B' },
    { score: 75, grade: 'C+' },
    { score: 70, grade: 'C' },
    { score: 65, grade: 'D+' },
    { score: 60, grade: 'D' },
    { score: 49, grade: 'F' }
];


const ActionClippingManagementComponent: React.FC<ActionMediaInfoProps> = ({ onOpen, onSubmit, onClose, isEditing, Data }) => {

    //for category
    const [mediaOption, setMediaOption] = useState<MediaOption[] | undefined>();
    const [mediaSelected, setMediaSelected] = useState<MediaOption | undefined>();

    const [autoDetect, setAutodetect] = useState<MediaOption | undefined>();

    //for upload img
    const [imgSelected, setImgSelected] = useState<File[]>();

    //for upload online
    const [isOnlineMedia, setIsOnlineMedia] = useState(true);

    //for show site visitor per month
    const [siteVisitor, setSiteVisitor] = useState(0);
    const [prScore, setPrScore] = useState(0);
    const [prCredit, setPrCredit] = useState('');

    //for show print bw,color,print count
    const [isPrintMedia, setIsPrintMedia] = useState(false);
    const [printPrice, setPrintPrice] = useState<number>(0);

    //for show clip url
    const [isRadioAndTV, setIsRadioAndTV] = useState(false);

    const { register, handleSubmit, reset, formState: { errors }, setValue, getValues, clearErrors, setError, watch } = useForm<AddClippingProp>({
        defaultValues: {
            category: NaN,
            headline_score: '5',
            content_score: '5',
            sentiment_score: '10',
            media_score: '5',
            clip_url: '',
            select_date: dayjs().format('DD-MM-YYYY')
        }
    });

    const handleFormSubmit: SubmitHandler<AddClippingProp> = async (data) => {
        onSubmit({ ...data, file: imgSelected });
        setImgSelected(undefined);
        handleOnClose();
    };

    useEffect(() => {
        setIsOnlineMedia(true);
        reset();
        const fetchData = async () => {
            try {
                const data = await getMediaInfoService();
                setMediaOption(data);
            } catch (err: any) {
                alertError('Service Fetch Media Error')
            }
        };
        fetchData();
    }, [onOpen]);


    const handleOnClose = () => {
        setAutodetect(undefined);
        setMediaSelected(undefined);
        setIsOnlineMedia(false);
        setIsPrintMedia(false);
        setIsRadioAndTV(false);
        onClose();
        reset();
    }

    const validateFloat = (value: any): boolean => {
        const numberRegex = /^-?\d*\.?\d+$/;
        return numberRegex.test(value);
    };


    // for upload img
    const handleImages = (selectedImages: File[]) => {
        setImgSelected(selectedImages);
    };

    const getPRScore = (): number => {
        const mediaScore = parseInt(getValues('media_score') || '0') * 40 / 5;
        const headlineScore = parseInt(getValues('headline_score') || '0') * 10 / 5;
        const contentScore = parseInt(getValues('content_score') || '0') * 30 / 5;
        const sentimentScore = parseInt(getValues('sentiment_score'))
        return mediaScore + headlineScore + contentScore + sentimentScore;
    };

    const watchedFields = watch(['media_score', 'headline_score', 'content_score', 'sentiment_score']);
    useEffect(() => {
        const score = getPRScore();
        setPrScore(score);
        setValue('pr_score', String(score));

        const credit = getGrade(score);
        setPrCredit(credit);
        setValue('pr_credit', credit);
    }, [watchedFields]);


    const getGrade = (score: number): string => {
        for (let i = 0; i < gradingScale.length; i++) {
            if (score >= gradingScale[i].score) {
                return gradingScale[i].grade;
            }
        }
        return 'F';
    };

    const getNewsPaperPrice = (type: string) => {

        const currentMedia = mediaOption?.find(item => item.id === getValues("category"));
        if (type === 'black-white') {
            return currentMedia?.bw_newspaper || 0;
        } else if (type === 'color') {
            return currentMedia?.color_newspaper || 0;
        } else {
            return 0
        }
    }

    const [url, setUrl] = useState<string>('');
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const getDomain = (url: string) => {

        if (!url) return;
        // Add a default protocol if not present
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
        }

        // Create a URL object
        const parsedUrl = new URL(url);

        // Return the origin (protocol + domain)
        return parsedUrl.hostname;
    }

    const handleUrlChange = async (event: any) => {
        const newUrl = event.target.value;
        setUrl(newUrl);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Return a Promise that resolves after a delay
        return new Promise((resolve) => {
            const newTimeoutId = setTimeout(() => {
                resolve(getDomain(newUrl));
            }, 500); // 500ms delay

            setTimeoutId(newTimeoutId);
        });
    };

    return (
        <>
            <Dialog fullWidth maxWidth={'md'} open={onOpen} onClose={() => { handleOnClose(); }}>

                <DialogTitle> ADD NEWS CLIPPING</DialogTitle>
                <DialogContent>
                    <form className="d-flex flex-column py-2" onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="row">
                            <div className="col-12">
                                <b>Clipping information</b>
                            </div>
                            <div className="col-12">
                                {isOnlineMedia ?
                                    <FormControl className="py-2" fullWidth size="small">
                                        <TextField size="small" label={<span>Url<span className="text-danger">*</span></span>}
                                            focused
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#FF0000",
                                                },
                                            }}
                                            {...register('url',
                                                {
                                                    required: errorMessage.required,
                                                }
                                            )}
                                            onChange={async (e) => {
                                                    let inputUrl = await handleUrlChange(e)
                                                    let media = mediaOption?.find((item: any) => item.category.title === 'Online' && item.url.includes(inputUrl));
                                                    if (media) {

                                                        setValue('category', media?.id);
                                                        setValue('media_score', String(media?.score || 0));

                                                        clearErrors('category');
                                                        clearErrors('media_score');

                                                        const publishingValue = media.price || 0
                                                        setValue('publishing', String(publishingValue), { shouldTouch: true })

                                                        const calculatedValue = publishingValue ? publishingValue : 0;
                                                        setValue('ad_value', String(calculatedValue.toFixed(2)), { shouldValidate: true });
                                                        setValue('pr_value', String((calculatedValue * 3).toFixed(2)), { shouldValidate: true });

                                                        setSiteVisitor(media.site_visitor_per_month);
                                                        setIsOnlineMedia(true);

                                                        // reset print
                                                        setIsPrintMedia(false);
                                                        // reset tv & radio
                                                        setIsRadioAndTV(false);

                                                        setMediaSelected(media);
                                                        setAutodetect(media);
                                                    }else{
                                                        setAutodetect(undefined);
                                                    }
                                            }}
                                        />
                                        {errors.url && <FormHelperText className="text-danger">{errors.url.message}</FormHelperText>}
                                    </FormControl> :
                                    <FormControl className="py-2" fullWidth size="small">
                                        <TextField size="small" label={<span>Url <span className="text-danger">*</span></span>}
                                            focused
                                            defaultValue={'-'}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#FF0000",
                                                },
                                            }}
                                            {...register('url')}
                                            disabled />
                                        {errors.url && <FormHelperText className="text-danger">{errors.url.message}</FormHelperText>}
                                    </FormControl>
                                }
                            </div>

                            {!autoDetect ? <div className="col-12">
                                <FormControl className="my-2" fullWidth size="small">
                                    <Autocomplete
                                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                                        {...register('category',
                                            {
                                                required: errorMessage.required
                                            }
                                        )}

                                        size="small"
                                        disablePortal
                                        options={mediaOption || []}
                                        getOptionLabel={(option: MediaOption) => option.title}
                                        renderInput={(params) => <TextField {...params} label='Media Name *' />}
                                        isOptionEqualToValue={(option: any, value) => option.id === value.id}
                                        onChange={(event, newInputValue) => {
                                            reset();
                                            const media = newInputValue || undefined;
                                            if (media) {
                                                setValue('category', media?.id);
                                                setValue('media_score', String(media?.score || 0));

                                                clearErrors('category');
                                                clearErrors('media_score');

                                                // check online case
                                                if (media.category.title === 'Online' || media.category.title === 'Social-Media') {
                                                    const publishingValue = media.price || 0
                                                    setValue('publishing', String(publishingValue), { shouldTouch: true })

                                                    const calculatedValue = publishingValue ? publishingValue : 0;
                                                    setValue('ad_value', String(calculatedValue.toFixed(2)), { shouldValidate: true });
                                                    setValue('pr_value', String((calculatedValue * 3).toFixed(2)), { shouldValidate: true });

                                                    setSiteVisitor(media.site_visitor_per_month);
                                                    setIsOnlineMedia(true);

                                                    // reset print
                                                    setIsPrintMedia(false);
                                                    // reset tv & radio
                                                    setIsRadioAndTV(false);
                                                } else if (media.category.title === 'Print') {
                                                    setIsPrintMedia(true);

                                                    let mediaPrice = getNewsPaperPrice('color');
                                                    setPrintPrice(mediaPrice);

                                                    //another
                                                    setSiteVisitor(0);
                                                    setIsOnlineMedia(false);

                                                    // reset tv & radio
                                                    setIsRadioAndTV(false);
                                                } else {
                                                    setSiteVisitor(0);
                                                    setIsOnlineMedia(false);
                                                    clearErrors('url');

                                                    setIsRadioAndTV(true);

                                                    // reset print
                                                    setIsPrintMedia(false);
                                                }

                                            } else {
                                                setValue('media_score', '0');
                                                setError('category', { type: 'required', message: errorMessage.required });
                                                setError('media_score', { type: 'required', message: errorMessage.required });
                                            }

                                            setMediaSelected(media);
                                        }}
                                    />


                                    {errors.category && <FormHelperText className="text-danger">{errors.category.message}</FormHelperText>}
                                </FormControl>
                            </div> : <div className="col-12">
                                <TextField disabled className="w-100" size="small" label={<span>Auto Detect Media<span className="text-danger">*</span></span>} value={mediaSelected?.title} />
                            </div>}
                            <div className="col-12">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" label={<span>Head line <span className="text-danger">*</span></span>} defaultValue={Data?.title} focused {...register('title', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                                    {errors.title && <FormHelperText className="text-danger" >{errors.title.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" defaultValue={''} label={<span>Publishing <span className="text-danger">*</span></span>}
                                        focused
                                        disabled={getValues('category') ? false : true}
                                        {...register('publishing',
                                            {
                                                required: errorMessage.required,
                                                validate: (value) => validateFloat(value) || 'Please enter a valid float number',
                                                onChange: (e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^-?\d.]/g, '');
                                                    setValue('publishing', value, { shouldValidate: true });

                                                    if (isPrintMedia) {
                                                        const calculatedValue = value ? (value * (printPrice || 0)) : 0;
                                                        setValue('ad_value', String(calculatedValue.toFixed(2)), { shouldValidate: true });
                                                        setValue('pr_value', String((calculatedValue * 3).toFixed(2)), { shouldValidate: true });
                                                    } else if (mediaSelected?.category?.title === 'TV' || mediaSelected?.category?.title === 'Radio') {
                                                        const calculatedValue = (Math.floor(value) * mediaSelected?.price) + ((((value - Math.floor(value)) * 10) / 6) * mediaSelected?.price);
                                                        setValue('ad_value', String(calculatedValue.toFixed(2)), { shouldValidate: true });
                                                        setValue('pr_value', String((calculatedValue * 3).toFixed(2)), { shouldValidate: true });
                                                    } else {
                                                        const calculatedValue = value ? parseFloat(value) : 0;
                                                        setValue('ad_value', String(calculatedValue.toFixed(2)), { shouldValidate: true });
                                                        setValue('pr_value', String((calculatedValue * 3).toFixed(2)), { shouldValidate: true });
                                                    }
                                                }
                                            }
                                        )} />
                                    {errors.publishing && <FormHelperText className="text-danger">{errors.publishing.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <InputLabel style={{ marginTop: '8px' }}>Headline Score</InputLabel>
                                    <Select
                                        label="Headline Score"
                                        defaultValue={5}
                                        {...register('headline_score',
                                            {
                                                required: errorMessage.required,
                                                onChange: (e) => {
                                                    setValue('headline_score', e.target.value);
                                                }
                                            }
                                        )}
                                        disabled={getValues('category') ? false : true}
                                    >
                                        {scoreOptions.map((i: any, index: any) => <MenuItem key={index} value={i}>{i}</MenuItem>)}
                                    </Select>
                                    {errors.content_score && <FormHelperText className="text-danger">{errors.content_score.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <InputLabel style={{ marginTop: '8px' }}>Content Score</InputLabel>
                                    <Select
                                        label="Content Score"
                                        defaultValue={5}
                                        {...register('content_score')}
                                        disabled={getValues('category') ? false : true}
                                    >
                                        {scoreOptions.map((i: any, index: any) => <MenuItem key={index} value={i}>{i}</MenuItem>)}
                                    </Select>
                                    {errors.content_score && <FormHelperText className="text-danger">{errors.content_score.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <InputLabel style={{ marginTop: '8px' }}>Sentiment Score</InputLabel>
                                    <Select
                                        label="Sentiment Score"
                                        disabled={getValues('category') ? false : true}
                                        defaultValue={10}
                                        {...register('sentiment_score')}
                                    >
                                        {sentimentOption.map((i: any, index: any) => <MenuItem key={index} value={i.score}>{i.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Select Date"
                                            format="DD-MM-YYYY"
                                            disabled={getValues('category') ? false : true}
                                            defaultValue={dayjs()}
                                            onChange={(date) => setValue('select_date', dayjs(date).format('DD-MM-YYYY'))}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                            {isPrintMedia && <>
                                <div className="col-4">
                                    <FormControl className="py-2" fullWidth size="small">
                                        <InputLabel style={{ marginTop: '8px' }}>Black-White , Color</InputLabel>
                                        <Select
                                            label="Black-White , Color"
                                            defaultValue={'color'}
                                            {...register('print_type',
                                                {
                                                    required: errorMessage.required,
                                                    onChange: (e) => {
                                                        let value = e.target.value;
                                                        let publishingValue = parseFloat(getValues("publishing"));
                                                        let mediaPrice = getNewsPaperPrice(value);
                                                        setPrintPrice(mediaPrice);

                                                        const calculatedValue = publishingValue ? (publishingValue * (mediaPrice || 0)) : 0;
                                                        setValue('ad_value', String(calculatedValue.toFixed(2)), { shouldValidate: true });
                                                        setValue('pr_value', String((calculatedValue * 3).toFixed(2)), { shouldValidate: true });
                                                    }
                                                }
                                            )}
                                        >
                                            {printOptions.map((i: any, index: any) => <MenuItem key={index} value={i}>{i}</MenuItem>)}
                                        </Select>
                                        {errors.print_type && <FormHelperText className="text-danger">{errors.print_type.message}</FormHelperText>}
                                    </FormControl>
                                </div>

                            </>
                            }
                            {isRadioAndTV && (
                                <div className="col-12">
                                    <FormControl className="py-2" fullWidth size="small">
                                        <TextField size="small" label={<span>Source Url <span className="text-danger">*</span></span>}
                                            focused
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#FF0000",
                                                },
                                            }}
                                            {...register('clip_url', {
                                                required: errorMessage.required
                                            })} />
                                        {errors.clip_url && <FormHelperText className="text-danger">{errors.clip_url.message}</FormHelperText>}
                                    </FormControl>
                                </div>
                            )}
                            <hr />
                            <div className="col-12">
                                <b>Auto Generate</b>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" defaultValue={0} label={<span>AD Value <span className="text-danger">*</span></span>}
                                        focused
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#FF0000",
                                            },
                                        }}
                                        {...register('ad_value',
                                            {
                                                required: errorMessage.required,
                                                validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                            }
                                        )} disabled />
                                    {errors.ad_value && <FormHelperText className="text-danger">{errors.ad_value.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" defaultValue={0} label={<span>PR Value <span className="text-danger">*</span></span>}
                                        focused
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#FF0000",
                                            },
                                        }}
                                        {...register('pr_value',
                                            {
                                                required: errorMessage.required,
                                                validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                            }
                                        )} disabled />
                                    {errors.pr_value && <FormHelperText className="text-danger">{errors.pr_value.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" defaultValue={0} label={<span>Media Score <span className="text-danger">*</span></span>}
                                        focused
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#FF0000",
                                            },
                                        }}
                                        {...register('media_score',
                                            {
                                                required: errorMessage.required,
                                                validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                            }
                                        )} disabled />
                                    {errors.media_score && <FormHelperText className="text-danger">{errors.media_score.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" value={prScore} label={<span>PR Score <span className="text-danger">*</span></span>}
                                        focused
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#FF0000",
                                            },
                                        }}
                                        {...register('pr_score')} disabled />
                                    {errors.pr_score && <FormHelperText className="text-danger">{errors.pr_score.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" value={prCredit} label={<span>PR Credit <span className="text-danger">*</span></span>}
                                        focused
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#FF0000",
                                            },
                                        }}
                                        {...register('pr_credit')} disabled />
                                    {errors.ad_value && <FormHelperText className="text-danger">{errors.ad_value.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            <div className="col-4">
                                {siteVisitor !== 0 && <FormControl className="py-2" fullWidth size="small">
                                    <TextField size="small" value={siteVisitor} label={<span>Site Visitor / Month <span className="text-danger">*</span></span>}
                                        focused
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#FF0000",
                                            },
                                        }}
                                        disabled />
                                    {errors.ad_value && <FormHelperText className="text-danger">{errors.ad_value.message}</FormHelperText>}
                                </FormControl>}
                            </div>
                            <div className="col-12">
                                <MultipleImgUploaderComponent onImagesSelected={handleImages} />
                            </div>

                            <div className="col-12">
                                <DialogActions>
                                    <Button onClick={() => { handleOnClose(); }}>Cancel</Button>
                                    <Button color="primary" variant="contained" type="submit">ADD</Button>
                                </DialogActions>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ActionClippingManagementComponent;
