import React, { useEffect, useState } from 'react';
import { delUserService, editUserService, getUserFindAllService } from '../../../services/userService';
import GeneratUserComponent from './generateUserComponent';
import DataTableComponent from '../../../components/TableComponent';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ActionUserDialogComponent from './actionUserDialogComponent';
import { alertError, alertSuccess } from '../../../utils/notification';

interface Data {
    id: string;
    username: string;
    createdAt: number;
    role: Role;
    status: number;
    created_at: string;
}

interface Role {
    id: string;
    title: string;
}



const UserManagementPage: React.FC = () => {
    const [rows, setRows] = useState<Data[]>([]);
    // for search
    const [searchQuery, setSearchQuery] = useState<string>('');

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [selectedUserData, setSelectedUserData] = useState<any>(null);

    const [isError, setIsError] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState<number | null>(null)


    const columns = [
        {
            name: 'Name',
            selector: (row: any) => <div className='d-flex align-items-center'>
                {row.imgSource ? (<img src={row.imgSource.imgUrl} alt="" className="rounded-circle" style={{ width: '40px', height: '40px' }} />) : (<img src="./icon/no-img.png" alt="" className="rounded-circle" style={{ width: '40px', height: '40px' }} />)}
                <div className='d-flex gap-1 mx-2'>
                    <div>{row.firstName}</div>
                    <div>{row.lastName}</div>
                </div>
            </div>,
            sortable: true,
        },
        {
            name: 'Username',
            selector: (row: any) => row.username,
        },
        {
            name: 'Role',
            selector: (row: any) => row.role.title,
        },
        {
            name: 'Status',
            selector: (row: any) => row.status ? <span className="badge bg-success  ml-2">Active</span> : <span className="badge bg-secondary  ml-2">InActive</span>,
        },
        {
            name: 'Action',
            headerStyle: { textAlign: 'center' },
            selector: (row: any) => <div>
                <button onClick={() => handleEditClick(row)} className='bg-transparent ms-1' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/edit.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
                <button onClick={() => onOpenDeleteModal(row)} className='bg-transparent' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/delete.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
            </div>,
        },
    ];


    const fetchDataAfterUserGeneration = async () => {
        try {
            const data = await getUserFindAllService();
            setRows(data);
        } catch (err: any) {
            console.log('err');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getUserFindAllService();
                setRows(data);
            } catch (err: any) {
                setIsError(true);
            }
        };
        fetchData();
    }, []);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = rows.filter((row) => {
        return (
            row.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.id.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const handleEditClick = (userData: any) => {
        setSelectedUserData(userData);
        setIsDialogOpen(true);
    };

    const handleConfirm = async (data: any) => {
        setIsDialogOpen(false);
        try {
            await editUserService(data);
            await fetchDataAfterUserGeneration();

            alertSuccess('Update success fully');
        } catch (error: any) {
            setIsError(true);
            console.log(error)
            alertError('Service Error!');
        }
    };

    const handleConfirmDelete = async () => {
        setDeleteModal(false);
        try {
            if(deleteUserId){
                await delUserService(deleteUserId);
                await fetchDataAfterUserGeneration();
                alertSuccess('Update success fully');
            }else{
                alertError('Something went wrong!');
            }
        } catch (error) {
            console.log(error)
            alertError('Service Error!');
        }
    }

    const onOpenDeleteModal = (data:any) => {
        setDeleteModal(true);
        setDeleteUserId(data.id)
    }



    return (
        <div className='py-2'>
            <div className='container-fluid card p-2'>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <div className='position-relative'>
                            <SearchIcon className='position-absolute' style={{ top: '50%', left: '20px', transform: 'translate(-50%, -50%)' }} />
                            <input
                                className='form-control h-100 rounded-pill'
                                type="text"
                                placeholder="Search.."
                                value={searchQuery}
                                onChange={handleSearch}
                                style={{ maxWidth: '50%', paddingLeft: '30px' }}
                            />
                        </div>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <GeneratUserComponent onSuccess={fetchDataAfterUserGeneration} />
                    </div>
                </div>
                <DataTableComponent data={filteredRows} columns={columns} />
                <ActionUserDialogComponent
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    onSubmit={(data) => {
                        handleConfirm(data);
                    }}
                    title="Edit User"
                    buttonText="Update"
                    isEditing={true}
                    userData={selectedUserData}
                />
            </div>


            <Dialog open={isError} onClose={() => setIsError(false)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Service Error!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsError(false)}>Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={deleteModal} onClose={() => {setDeleteModal(false); setDeleteUserId(null);}}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDeleteModal(false); setDeleteUserId(null);}}>Close</Button>
                    <Button color='error' onClick={() => handleConfirmDelete()}>Delete</Button>
                </DialogActions>
            </Dialog>


           
        </div>
    );
};

export default UserManagementPage;
