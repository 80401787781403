import axiosInstance from "./axiosInterceptor";

const API_ENDPOINTS = {
    login: '/auth/login', 
};

export const loginService = async (credentials: { username: string, password: string }) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.login, credentials);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message || 'Failed to login');
    }
};