import { useEffect, useState } from "react";
import DataTableComponent from "../../../components/TableComponent";

import SearchIcon from '@mui/icons-material/Search';
import { createMediaInfoService, delMediaInfoService, editMediaInfoService, getMediaInfoService } from "../../../services/newsService";
import { alertError, alertSuccess } from "../../../utils/notification";
import ActionMediaInfoComponent from "./actionMediaInfoComponent";
import { Button, Dialog, DialogContent, DialogTitle ,ButtonGroup} from "@mui/material";

interface MediaInfoData {
    id: string;
    title: string;
    price: number;
    score: number;
    imgSource: imgSource;
    category: category;
}

interface imgSource {
    id: number;
    imgUrl: string;
}

interface category {
    id: string;
    title: string;
    unit: string;
    active: boolean;
}

const MediaManageMentPage = () => {

    const [rows, setRows] = useState<MediaInfoData[]>([]);
    // for search
    const [searchQuery, setSearchQuery] = useState<string>('');

    // for delete
    const [onConfirm, setOnConfirm] = useState(false);
    const [deleteMediaId, setDeleteMediaId] = useState(null);

    // for add
    const [onOpenAddModal, setOnOpenAddModal] = useState(false);

    // for edit
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [currentSelectMedia, setCurrentSelectedMedia] = useState<any>();



    const columns = [
        {
            name: 'Media',
            selector: (row: any) => <div className="d-flex gap-1 align-items-center">
                                        { row.imgSource ? (<img src={row.imgSource.imgUrl} alt="" className="rounded-circle" style={{ width: '40px', height: '40px'}}/>) : ( <img src="./icon/no-img.png" alt="" className="rounded-circle" style={{ width: '40px', height: '40px'}}/>) }
                                        <b>{row.title}</b>
                                    </div>
        },
        {
            name: 'Media Type',
            selector: (row: any) => row?.category.title,
        },
        {
            name: 'Media Score',
            selector: (row: any) => row?.score,
            sortable: true,
        },
        {
            name: 'Media Price',
            selector: (row: any) => row?.price,
            sortable: true,
        },
        {
            name: 'Print Config',
            selector: (row: any) => row?.category?.title === 'Print' ? <div className="d-flex flex-column">
            <small>Print Count : {row.print_count_newspaper}</small>
            <small>Back White : {row.bw_newspaper}</small>
            <small>Color : {row.color_newspaper}</small>
            </div> : <>-</>,
        },
        {
            name: 'Online Config',
            selector: (row: any) => row?.category?.title === 'Online' ? <div className="d-flex flex-column">
            <small>Site V/M : {row.site_visitor_per_month}</small>
            <small>Url : {row.url}</small>
            </div> : <>-</>,
        },
        {
            name: 'Action',
            headerStyle: { textAlign: 'center' },
            selector: (row: any) => <div>
                <button onClick={() => { handleOpenEditModal(row); }} className='bg-transparent ms-1' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/edit.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
                <button onClick={() => {setDeleteMediaId(row.id); setOnConfirm(true); }} className='bg-transparent' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/delete.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
            </div>,
        },
    ];


    const fetchDataAfterAction = async () => {
        try {
            const data = await getMediaInfoService();
            setRows(data);
        } catch (err: any) {
            alertError('Service Fetch Media Error')
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = rows.filter((row) => {
        return (
            row.title.toLowerCase().includes(searchQuery.toLowerCase())

        );
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getMediaInfoService();
                setRows(data);
            } catch (err: any) {
                alertError('Service Fetch Media Error')
            }
        };
        fetchData();
    }, []);

    const handleSaveAddMediaInfo = async (data:any) => {
        try {
            await createMediaInfoService(data);
            fetchDataAfterAction();
            alertSuccess('Add Media successfully');
        } catch (error) {
            alertError('Service Error');
        }
    }

    const handleConfirmDelete = async () => {
        setOnConfirm(false);
        try {
            if(deleteMediaId){
                await delMediaInfoService(deleteMediaId);
                await fetchDataAfterAction();
                alertSuccess('Deleted successfully');
            }else{
                alertError('Service Error!');
            }
        } catch (error) {
            console.log(error)
            alertError('Service Error!');
        }
    }


    // for edit

    const handleOpenEditModal = (data:any) => {
        setOnOpenEditModal(true);
        setCurrentSelectedMedia(data);
    }

    const handleEditMediaInfo = async (data:any) => {
        try {
            await editMediaInfoService(currentSelectMedia.id, data);
            fetchDataAfterAction();
            alertSuccess('Edited Media successfully');
        } catch (error) {
            alertError('Service Error');
        }
    }



    return <div className='py-2'>
        <div className='container-fluid card p-2'>
            <div className='row mb-2'>
                <div className='col-6'>
                    <div className='position-relative'>
                        <SearchIcon className='position-absolute' style={{ top: '50%', left: '20px', transform: 'translate(-50%, -50%)    ' }} />
                        <input
                            className='form-control h-100 rounded-pill'
                            type="text"
                            placeholder="Search.."
                            value={searchQuery}
                            onChange={handleSearch}
                            style={{ maxWidth: '50%', paddingLeft: '30px' }}
                        />
                    </div>
                </div>
                <div className='col-6 d-flex justify-content-end'>

                <ButtonGroup variant="contained" aria-label="Basic button group">
                    <Button onClick={() => { setOnOpenAddModal(true); }}>+ ADD MEDIA</Button>
                </ButtonGroup>


                <ActionMediaInfoComponent 
                        onSubmit={(data)=>{
                            handleSaveAddMediaInfo(data);
                        }} 
                        onOpen={onOpenAddModal}
                        onClose={()=> setOnOpenAddModal(false) }
                    />

                <ActionMediaInfoComponent 
                        onSubmit={(data)=>{
                            handleEditMediaInfo(data);
                        }} 
                        onOpen={onOpenEditModal}
                        onClose={()=> setOnOpenEditModal(false) }
                        isEditing={true}
                        Data={currentSelectMedia}
                    />

                </div>
            </div>
            <DataTableComponent data={filteredRows} columns={columns} />
        </div>

        <Dialog open={onConfirm} >
            <DialogTitle>Comfirm Add Media</DialogTitle>
            <DialogContent>
                Are you sure to Delete Media?
                <div className="w-100 d-flex gap-1 justify-content-center py-2">
                    <Button color="error" variant="contained" onClick={() => { setOnConfirm(false) }}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={() => { handleConfirmDelete(); }} type="submit">Submit</Button>
                </div>
            </DialogContent>
        </Dialog>



    </div>
}

export default MediaManageMentPage;