import { useEffect, useState } from "react";
import { delClippingService, fetchImageByUrl, getClippingService } from "../../services/clippingService";
import { alertError, alertSuccess } from "../../utils/notification";
import ExportFunction from "./export";
import { formatDate } from "../../utils/utils";
import { Box, Button, Checkbox, CircularProgress, Modal } from "@mui/material";

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import pdficon from "./icon-pdf.webp"
import imgicon from "./icon-jpeg.webp"
import viewicon from "./icon-View.webp"
import binicon from "./icon-bin.jpg"
import download from "./download.png"
import MasterFilterComponent from "../Clipping/masterFilterComponent";

const BetaClippingPage = () => {
    const [rows, setRows] = useState<any[]>([]);
    const [downloadRows, setDownloadRows] = useState<any[]>([]);
    const [downlonding, setDownloading] = useState<boolean>(false);
    const [typeDownload, setTypeDownload] = useState('');
    const [itemListLength, setItemListLength] = useState(0);
    const [exportListModal, setExportListModal] = useState(false);
    const [exportRows, setExportRows] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const handleOpenExportList = async (open : boolean = false) => {

        if(!open) setExportListModal(true);
        const itemList = localStorage.getItem("itemList");
        const localItemList = itemList ? JSON.parse(itemList) : [];
        const selectedItem = await fetchDataWithId(localItemList);
        setExportRows(selectedItem)

        console.log( "exportRows :", exportRows)
    }


    const getImg = async (imgList: any) => {
        let newImgList: any[] = []; // Initialize newImgList as an empty array

        // Use for...of loop to iterate over imgList asynchronously
        for (const item of imgList) {
            const payload = {
                imgList: [
                    {
                        img_media: "",
                        img_content: [item.img_sources[0].img_source],
                    },
                ],
            };

            // Await the result of fetchImageByUrl and push it to newImgList
            const result = await fetchImageByUrl(payload);
            newImgList.push(result[0]);
        }
        return newImgList; // Return newImgList containing fetched images
    }

    const getImgCat = async (category: any) => {
        if (!category.imgSource) return null;

        let newImgList: any[] = []; // Initialize newImgList as an empty array
        // Use for...of loop to iterate over imgList asynchronously
        const payload = {
            imgList: [
                {
                    img_media: "",
                    img_content: [category.imgSource.imgUrl],
                },
            ],
        };
        const result = await fetchImageByUrl(payload);
        newImgList.push(result[0]);
        return newImgList; // Return newImgList containing fetched images
    }

    const fetchDataWithId = async (idList: any) => {
        try {
            if (idList.length <= 0) return [];

            const itemList = localStorage.getItem("itemList");
            const localItemList = itemList ? JSON.parse(itemList) : [];
            const data = await getClippingService({
                company: 0,
                date_range: [null, null],
                project_id: 0,
                sub_project: 0,
                clipping_id_list: idList,
            });

            // Assign the checked field based on localItemList
            const updatedData = await Promise.all(data.map(async (item: any) => ({
                ...item,
                imgList: await getImg(item.imgList),
                imgMedia: await getImgCat(item.category)
            })));

            return updatedData;

        } catch (err: any) {
            alertError("Service Fetch Media Error");
            return [];
        }
    };

    const handleCheckboxChange = (index: number) => {
        setRows(prevRows => {
            const newRows = [...prevRows];
            newRows[index].checked = !newRows[index].checked;

            const checkedItems = newRows
                .filter((row) => row.checked)
                .map((row) => row.id);

            localStorage.setItem("itemList", JSON.stringify(checkedItems));
            setItemListLength(checkedItems.length);

            return newRows;
        });



    };

    const handleFormSubmit = async (formData: any) => {

        try {
            setIsLoading(true);

            // Retrieve local item list from localStorage
            const itemList = localStorage.getItem("itemList");
            const localItemList = itemList ? JSON.parse(itemList) : [];
            const data = await getClippingService({
                ...formData,
                clipping_id_list: [],
            });

            // Assign the checked field based on localItemList
            const updatedData = await Promise.all(data.map(async (item: any) => ({
                ...item,
                checked: localItemList.includes(item.id),
                imgList: await getImg(item.imgList),
                imgMedia: await getImgCat(item.category)
            })));
            setRows(updatedData);
            setItemListLength(localItemList.length);

        } catch (error) {
            alertError("Service Fetch Media Error");
        } finally {
            setIsLoading(false);
        }
    };

    const onNavigateChildCipping = (id: number) => {
        window.location.href = `/viewclipping/${id}`;
    }

    // ------- modal -------
    const handleClose = () => setExportListModal(false);
    const handleClear = async () => {
        localStorage.setItem("itemList", JSON.stringify([]));
        setItemListLength(0);
        handleOpenExportList(true);

        await fetchDataWithOutReload();
    };
    const handleRemove = async (index : number) => {
        const updatedRows = exportRows.filter((_, i) => i !== index);
        setExportRows(updatedRows);

        const updatedIds = updatedRows.map(item => item.id);
        localStorage.setItem("itemList", JSON.stringify(updatedIds));
        setItemListLength(updatedRows.length);

        await fetchDataWithOutReload();
    };
    // ------- modal -------

    const onDelete = async (_id:string) => {
        let userConfirmed = window.confirm("Are you sure you want to proceed?");
        if(userConfirmed){
            try {
            
                if(_id){
                    await delClippingService(_id);
                    alertSuccess('Deleted successfully');
                }else{
                    alertError('Service Error!');
                }
            } catch (error) {
                alertError("Service Delete Clipping Error");
            } finally {
                await fetchDataWithOutReload();
            }
        }
        
    }


    const fetchDataWithOutReload = async () => {
        try {
            setIsLoading(true);
            const itemList = localStorage.getItem("itemList");
            const localItemList = itemList ? JSON.parse(itemList) : [];
            const data = await getClippingService({
                company: 0,
                date_range: [null, null],
                project_id: 0,
                sub_project: 0,
                clipping_id_list: [],
            });

            // Assign the checked field based on localItemList
            const updatedData = await Promise.all(data.map(async (item: any) => ({
                ...item,
                checked: localItemList.includes(item.id),
                imgList: await getImg(item.imgList),
                imgMedia: await getImgCat(item.category)
            })));

            setRows(updatedData);
            setItemListLength(localItemList.length);

            console.log(updatedData)

        } catch (err: any) {
            alertError("Service Fetch Media Error");
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const itemList = localStorage.getItem("itemList");
                const localItemList = itemList ? JSON.parse(itemList) : [];
                const data = await getClippingService({
                    company: 0,
                    date_range: [null, null],
                    project_id: 0,
                    sub_project: 0,
                    clipping_id_list: [],
                });

                // Assign the checked field based on localItemList
                const updatedData = await Promise.all(data.map(async (item: any) => ({
                    ...item,
                    checked: localItemList.includes(item.id),
                    imgList: await getImg(item.imgList),
                    imgMedia: await getImgCat(item.category)
                })));

                setRows(updatedData);
                setItemListLength(localItemList.length);

                console.log(updatedData)

            } catch (err: any) {
                alertError("Service Fetch Media Error");
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (downlonding) {
            setDownloading(false);
        }
    }, [downlonding])

    return (
        <div className="card">
            <div className="p-2 d-flex flex-column">
                <MasterFilterComponent onSubmit={handleFormSubmit} />
                <div className="d-flex">
                    {/* <ExportFunction data={[rows[4]]} type={typeDownload} dowloanding={downlonding} /> */}
                    <ExportFunction data={downloadRows} type={typeDownload} dowloanding={downlonding} />
                    <div
                        onClick={()=> handleOpenExportList()}
                        className="position-relative"
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            src={download}
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                        />
                        {itemListLength > 0 && (
                            <span
                                className="badge bg-danger position-absolute"
                                style={{ top: "-8px", right: "-8px" }}
                            >
                                {itemListLength}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            {isLoading ? <div className="w-100 d-flex justify-content-center p-4"><CircularProgress /></div> : <div className="container-fluid p-2 rounded">
                {
                    rows.map((item: any, index: number) => (
                        <div key={index} className="card p-2 d-flex flex-row" style={{ background: item.checked ? "rgba(108,195,198,255)" : "" }}>
                            <div className="p-2 d-flex align-items-center justify-content-center">
                                <Checkbox defaultChecked={item.checked} value={item.checked} onChange={() => handleCheckboxChange(index)} />
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex gap-2 align-items-center">
                                    <span className="border border-primary rounded"> {item?.category?.imgSource?.imgUrl ? <><img style={{ width: '25px', height: '25px' }} src={item?.category?.imgSource?.imgUrl} alt="" /></> : <div className="d-flex align-items-center justify-content-center" style={{ width: '25px', height: '25px' }} ></div>} </span>
                                    <small>(<small>{item?.category?.title}</small>)</small>
                                    {item?.title}
                                </div>
                                <div className="d-flex gap-1">
                                    <div>
                                        <small>{formatDate(item.select_date)}</small>
                                    </div>
                                    <div className="d-flex gap-1 align-items-center">
                                        <b>ad_value :</b>
                                        <small>{item.ad_value}</small>
                                    </div>
                                    <div className="d-flex gap-1 align-items-center">
                                        <b>pr_value :</b>
                                        <small>{item.pr_value}</small>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex gap-2">
                                <div style={{ cursor: 'pointer' }} onClick={() => { setDownloading(true); setTypeDownload('pdf'); setDownloadRows([item]); }}>
                                    <img style={{ width: "40px", height: "40px" }} src={pdficon} alt="" />
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => { setDownloading(true); setTypeDownload('img'); setDownloadRows([item]); }} >
                                    <img style={{ width: "40px", height: "40px" }} src={imgicon} alt="" />
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => { onNavigateChildCipping(item.id) }}>
                                    <img style={{ width: "40px", height: "40px" }} src={viewicon} alt="" />
                                </div>

                                <div style={{ cursor: 'pointer' }} onClickCapture={()=> { onDelete(item.id) }}>
                                    <img style={{ width: "30px", height: "30px" , marginTop : "3px" }} src={binicon} alt="" />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>}

            {/* modal export */}

            <Modal
                open={exportListModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{ onClick: (e) => e.stopPropagation() }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 1200,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <div>
                        {
                          exportRows.length > 0 ?  
                          
                          (

                            exportRows.map((item: any, index: number) => (
                                <div key={index} className="card p-2 d-flex flex-row" style={{ background: item.checked ? "rgba(108,195,198,255)" : "" }}>
                                    <div className="flex-grow-1">
                                        <div className="d-flex gap-2 align-items-center">
                                            <span className="border border-primary rounded"> {item?.category?.imgSource?.imgUrl ? <><img style={{ width: '25px', height: '25px' }} src={item?.category?.imgSource?.imgUrl} alt="" /></> : <div className="d-flex align-items-center justify-content-center" style={{ width: '25px', height: '25px' }} >{item?.category?.title}</div>} </span>
                                            <small>(<small>{item?.category?.title}</small>)</small>
                                            {item?.title}
                                        </div>
                                        <div className="d-flex gap-1">
                                            <div>
                                                <small>{formatDate(item.select_date)}</small>
                                            </div>
                                            <div className="d-flex gap-1 align-items-center">
                                                <b>ad_value :</b>
                                                <small>{item.ad_value}</small>
                                            </div>
                                            <div className="d-flex gap-1 align-items-center">
                                                <b>pr_value :</b>
                                                <small>{item.pr_value}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <div className="d-flex align-items-center justify-content-center" onClick={() => handleRemove(index)} style={{ cursor: 'pointer' }}>
                                            <RemoveCircleIcon className="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            ))

                          )
                          : <div>No Export Data !</div>
                        } 
                    </div>

                    <div className="d-flex gap-1">
                        <Button
                            onClick={()=>{ setDownloading(true); setTypeDownload('pdf'); setDownloadRows(exportRows);}}
                            variant="contained"
                            sx={{ mt: 2 }}
                        >Dowload All As PDF</Button>
                        <Button
                            onClick={()=>{ setDownloading(true); setTypeDownload('img'); setDownloadRows(exportRows); }}
                            variant="contained"
                            sx={{ mt: 2 }}
                        >Dowload All As IMAGE</Button>
                        <Button
                            onClick={handleClear}
                            variant="contained"
                            color="error"
                            sx={{ mt: 2 }}
                        >Clear</Button>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color="error"
                            sx={{ mt: 2 }}
                        >Close</Button>
                    </div>
                </Box>
            </Modal>
            {/* modal export */}

        </div>
    )
}

export default BetaClippingPage;