// utils.ts
const formatDate = (isoDateString: string): string => {
    const date = new Date(isoDateString);
  
    const formatter = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit'
    });
  
    return formatter.format(date).replace(',', ''); // Remove the comma
  };
  
  export { formatDate };
  