import { useEffect, useState } from "react";
import { getOneClippingService } from "../../services/clippingService";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { formatDate } from "../../utils/utils";

const ViewClippingPage = () => {

    const { id } = useParams();
    const [data , setData] = useState<any>();


    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const response = await getOneClippingService(parseInt(id));
                    setData(response);
                } catch (error) {
                    console.error("Failed to fetch clipping:", error);
                }
            }
        };

        fetchData();
    }, [id]);



    return <div className="container-fluid">
        <div className="d-flex justify-content-between mb-2">
            <h3>View Clipping Detail</h3>
            <Button onClick={() => {
                                window.location.href = '/clipping'
                            }} variant="contained" color="primary">
                Clipping
            </Button>
        </div>
        <div className="row">
            <div className="col-12 mb-2">
                <div className="card p-2">
                    <b>Clipping Detail</b>
                    <div className="d-flex gap-2">
                       <div className="d-flex gap-1 align-items-center"><b>Client : </b> <small> {data?.company?.title}</small></div>
                       <div className="d-flex gap-1 align-items-center"><b>Project : </b> <small> {data?.project?.title}</small></div>
                       { data?.project.sub_project ? <div className="d-flex gap-1 align-items-center"><b>SubProject : </b> <small> {data?.project?.sub_project?.title}</small></div> : null}
                       <div className="d-flex gap-1 align-items-center"><b>Clipping : </b> <small> {data?.title}</small></div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="d-flex gap-1 align-items-center"><b>PR Value : </b> <small> {data?.pr_value}</small></div>
                        <div className="d-flex gap-1 align-items-center"><b>AD Value : </b> <small> {data?.ad_value}</small></div>
                        <div className="d-flex gap-1 align-items-center"><b>PR Score : </b> <small> {data?.pr_score}</small></div>
                        <div className="d-flex gap-1 align-items-center"><b>PR Credit : </b> <small> {data?.pr_credit}</small></div>
                        <div className="d-flex gap-1 align-items-center"><b>Sentiment Score : </b> <small> {data?.sentiment_score}</small></div>
                        <div className="d-flex gap-1 align-items-center"><b>Clip URL : </b> <small> {data?.clip_url}</small></div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                    <div className="card p-2">
                        <b>Clipping Img</b>
                    {
                        data?.imgList?.map((i : any , index : number)=> (
                            <div className="my-2 d-flex align-items-center justify-content-center" key={index}>
                                <img className="img-fluid rounded" src={i.img_sources[0].img_source} alt={`Clipping image ${index}`} style={{ maxWidth: '80%', height: 'auto', boxShadow : 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} />
                            </div>
                        ))
                    }
                    </div>
            </div>
        </div>
    </div>
}

export default ViewClippingPage;