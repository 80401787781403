import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import ImageUploadPreview from "../../../components/UploadImgComponent";
import { alertError } from "../../../utils/notification";
import { uploadFile } from "../../../services/firebaseService";
import { getMediaService } from "../../../services/newsService";

interface AddMediaProps {
    title: string;
    category_id: number;
    score: number;
    price: number;
    print_count_newspaper: number;
    bw_newspaper: number;
    color_newspaper: number;
    url: string;
    site_visitor_per_month: number;
}

interface MediaTypeOptions {
    id: number;
    title: string;
    unit: string;
}


enum errorMessage {
    required = 'this field is required',
    max = 'the field is maximum for',
    min = 'the field is minimum for',
}

const mediaScore = [1, 2, 3, 4, 5]

interface ActionMediaInfoProps {
    onOpen: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
    isEditing?: boolean;
    Data?: any;
}

const ActionMediaInfoComponent: React.FC<ActionMediaInfoProps> = ({ onOpen, onSubmit, onClose, isEditing, Data }) => {

    const [currentSelectedImg, setCurrentSelectedImg] = useState<any>();
    const [onConfirm, setOnConfirm] = useState(false);
    const [data, setData] = useState<any>();

    const [mediaTypeOptions, setMediaTypeOptions] = useState<MediaTypeOptions>();
    const [isShowNewsPaperConfig, setIsShowNewsPaperConfig] = useState(false);
    const [isShowOnlineConfig, setIsShowOnlineConfig] = useState(false);

    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm<AddMediaProps>({
        defaultValues : {
            price : 0
        }
    });
    const handleFormSubmit: SubmitHandler<AddMediaProps> = async (data) => {
        setData(data);
        setOnConfirm(true);
    };

    const onClearPrintForm = () => {
        setValue('bw_newspaper', Data?.bw_newspaper || 0);
        setValue('color_newspaper',Data?.color_newspaper || 0);
        setValue('print_count_newspaper',Data?.print_count_newspaper || 0);
    }

    const onClearOnlineForm = () => {
        setValue('url', Data?.url || '');
        setValue('site_visitor_per_month',Data?.site_visitor_per_month || 0);
    }

    const handleConfirmCreate = async () => {
        onClose();
        let imgUrl;
        try {
            if (currentSelectedImg && currentSelectedImg !== Data?.imgSource?.imgUrl) {
                imgUrl = await uploadFile(currentSelectedImg, 'P_MEDIA_INFO');
            } else {
                imgUrl = currentSelectedImg;
            }

            const { category_id, price, score, print_count_newspaper, bw_newspaper, color_newspaper, site_visitor_per_month, url,...rest } = data;
            onSubmit({
                ...rest,
                category_id: parseInt(category_id),
                price: parseFloat(price),
                score: parseInt(score),
                print_count_newspaper: parseInt(print_count_newspaper) || 0,
                bw_newspaper: parseFloat(bw_newspaper) || 0,
                color_newspaper: parseFloat(color_newspaper) || 0,
                site_visitor_per_month: parseInt(site_visitor_per_month) || 0,
                imgSource: imgUrl || '',
                url: url || '',
            });

        } catch (error) {
            console.log(error)
            alertError('Service Image Upload Fail')
        }
    }

    const validateFloat = (value: any): boolean => {
        const numberRegex = /^-?\d*\.?\d+$/;
        let testValue = numberRegex.test(value)
        return testValue;
    };

    useEffect(() => {
        reset();
        setCurrentSelectedImg('');

        if(Data?.category?.title === 'Print'){
            setIsShowNewsPaperConfig(true);
        }else{
            setIsShowNewsPaperConfig(false);
        }
        const fetchData = async () => {
            try {
                const data = await getMediaService();
                setMediaTypeOptions(data);
            } catch (err: any) {
                alertError('Service Error')
            }
        };
        fetchData();
    }, [onOpen]);


    return <>
        <Dialog fullWidth maxWidth={'sm'} open={onOpen} onClose={() => { onClose(); reset(); }}>
            <DialogTitle> {isEditing ? 'EDIT' : 'ADD'} MEDIA</DialogTitle>
            <DialogContent>
                <ImageUploadPreview onSubmit={(data) => {
                    setCurrentSelectedImg(data);
                }}
                    Data={Data}
                    isEditing={isEditing}
                />
                {
                    isEditing ? (<form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormControl className="py-2" fullWidth>
                            <TextField defaultValue={Data?.title} label='Media Name' focused {...register('title', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.title && <FormHelperText className="text-danger" >{errors.title.message}</FormHelperText>}
                        </FormControl>
                        {!isShowNewsPaperConfig && <FormControl className="py-2" fullWidth>
                            <TextField defaultValue={Data?.price} label='Media Rate' focused {...register('price',
                                {
                                    required: errorMessage.required,
                                    validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                }
                            )} />
                            {errors.price && <FormHelperText className="text-danger">{errors.price.message}</FormHelperText>}
                        </FormControl>}
                        <FormControl className="py-2" fullWidth>
                            <InputLabel>Media Type</InputLabel>
                            <Select
                                label="Media Type"
                                defaultValue={Data?.category.id}
                                {...register(
                                    'category_id', {
                                    required: errorMessage.required,
                                    onChange: (e) => {
                                        let selectedId = e.target.value || 0;
                                        let mediaType = Array.isArray(mediaTypeOptions) ? mediaTypeOptions.find(i => i.id === selectedId) : { title: '' };
                                        if (mediaType.title === 'Print') {
                                            onClearOnlineForm();
                                            setIsShowOnlineConfig(false);
                                            setIsShowNewsPaperConfig(true);
                                        } else if(mediaType.title === 'Online' || mediaType.title === 'Social-Media') {
                                            onClearPrintForm();
                                            setIsShowNewsPaperConfig(false);
                                            setIsShowOnlineConfig(true);
                                        }else{
                                            onClearOnlineForm();
                                            onClearPrintForm();
                                            setIsShowOnlineConfig(false);
                                            setIsShowNewsPaperConfig(false);
                                        }
                                    }
                                },
                                )}
                            >
                                {Array.isArray(mediaTypeOptions) && mediaTypeOptions.map((i: any) => <MenuItem key={i.id} value={i.id}>{i.title}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {isShowOnlineConfig && <>
                            <FormControl className="py-2" fullWidth>
                                <TextField defaultValue={Data?.url} label='Url' focused {...register('url',
                                    {
                                        required: errorMessage.required,
                                    }
                                )} />
                                {errors.url && <FormHelperText className="text-danger">{errors.url.message}</FormHelperText>}
                            </FormControl>
                            <FormControl className="py-2" fullWidth>
                                <TextField defaultValue={Data?.site_visitor_per_month} label='Site Visitor/Month' focused {...register('site_visitor_per_month',
                                    {
                                        required: errorMessage.required,
                                        validate: (value) => validateFloat(value) || 'Please enter a valid number'
                                    }
                                )} />
                                {errors.site_visitor_per_month && <FormHelperText className="text-danger">{errors.site_visitor_per_month.message}</FormHelperText>}
                            </FormControl>
                        </>}
                        {isShowNewsPaperConfig && <>
                            <FormControl className="py-2" fullWidth>
                                <TextField defaultValue={Data?.print_count_newspaper} label='Print Count Newspaper' focused {...register('print_count_newspaper',
                                    {
                                        required: errorMessage.required,
                                        validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                    }
                                )} />
                                {errors.print_count_newspaper && <FormHelperText className="text-danger">{errors.print_count_newspaper.message}</FormHelperText>}
                            </FormControl>
                            <div>
                                <div>Media Rate</div>
                                <FormControl className="py-2 ps-4" fullWidth>
                                    <TextField defaultValue={Data?.bw_newspaper}  label='Black - White Newspaper' focused {...register('bw_newspaper',
                                        {
                                            required: errorMessage.required,
                                            validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                        }
                                    )} />
                                    {errors.bw_newspaper && <FormHelperText className="text-danger">{errors.bw_newspaper.message}</FormHelperText>}
                                </FormControl>
                                <FormControl className="py-2 ps-4" fullWidth>
                                    <TextField defaultValue={Data?.color_newspaper} label='Color Newspaper' focused {...register('color_newspaper',
                                        {
                                            required: errorMessage.required,
                                            validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                        }
                                    )} />
                                    {errors.color_newspaper && <FormHelperText className="text-danger">{errors.color_newspaper.message}</FormHelperText>}
                                </FormControl>
                            </div>
                            
                        </>}
                        <FormControl className="py-2" fullWidth>
                            <InputLabel>Media Score</InputLabel>
                            <Select
                                label="Media Score"
                                defaultValue={Data?.score}
                                {...register('score', { required: errorMessage.required })}
                            >
                                {mediaScore.map((i: any, index: any) => <MenuItem key={index} value={i}>{i}</MenuItem>)}
                            </Select>
                        </FormControl>


                        <DialogActions>
                            <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                            <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>SUBMIT</Button>
                        </DialogActions>
                    </form>) : (<form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormControl className="py-2" fullWidth>
                            <TextField label='Media Name' focused {...register('title', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.title && <FormHelperText className="text-danger" >{errors.title.message}</FormHelperText>}
                        </FormControl>
                        {!isShowNewsPaperConfig && <FormControl className="py-2" fullWidth>
                            <TextField label='Media Rate' focused {...register('price',
                                {
                                    required: errorMessage.required,
                                    validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                }
                            )} />
                            {errors.price && <FormHelperText className="text-danger">{errors.price.message}</FormHelperText>}
                        </FormControl>}
                        <FormControl className="py-2" fullWidth>
                            <InputLabel>Media Type</InputLabel>
                            <Select
                                label="Media Type"
                                defaultValue={1}
                                {...register('category_id', {
                                    required: errorMessage.required,
                                    onChange: (e) => {
                                        let selectedId = e.target.value || 0;
                                        let mediaType = Array.isArray(mediaTypeOptions) ? mediaTypeOptions.find(i => i.id === selectedId) : { title: '' };
                                        if (mediaType.title === 'Print') {
                                            onClearOnlineForm();
                                            setIsShowOnlineConfig(false);
                                            setIsShowNewsPaperConfig(true);
                                        } else if(mediaType.title === 'Online' || mediaType.title === 'Social-Media') {
                                            onClearPrintForm();
                                            setIsShowNewsPaperConfig(false);
                                            setIsShowOnlineConfig(true);
                                        }else{
                                            onClearOnlineForm();
                                            onClearPrintForm();
                                            setIsShowOnlineConfig(false);
                                            setIsShowNewsPaperConfig(false);
                                        }
                                    }
                                })}
                            >
                                {Array.isArray(mediaTypeOptions) && mediaTypeOptions.map((i: any) => <MenuItem key={i.id} value={i.id}>{i.title}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {isShowOnlineConfig && <>
                            <FormControl className="py-2" fullWidth>
                                <TextField label='Url' focused {...register('url',
                                    {
                                        required: errorMessage.required,
                                    }
                                )} />
                                {errors.url && <FormHelperText className="text-danger">{errors.url.message}</FormHelperText>}
                            </FormControl>
                            <FormControl className="py-2" fullWidth>
                                <TextField label='Site Visitor/Month' focused {...register('site_visitor_per_month',
                                    {
                                        required: errorMessage.required,
                                        validate: (value) => validateFloat(value) || 'Please enter a valid number'
                                    }
                                )} />
                                {errors.site_visitor_per_month && <FormHelperText className="text-danger">{errors.site_visitor_per_month.message}</FormHelperText>}
                            </FormControl>
                        </>}
                        {isShowNewsPaperConfig && <>
                            <FormControl className="py-2" fullWidth>
                                <TextField label='Circulation' focused {...register('print_count_newspaper',
                                    {
                                        required: errorMessage.required,
                                        validate: (value) => validateFloat(value) || 'Please enter a valid number'
                                    }
                                )} />
                                {errors.print_count_newspaper && <FormHelperText className="text-danger">{errors.print_count_newspaper.message}</FormHelperText>}
                            </FormControl>
                            <div>
                                <div>Media Rate</div>
                                <FormControl className="py-2 ps-4" fullWidth>
                                    <TextField label='Black - White Newspaper' focused {...register('bw_newspaper',
                                        {
                                            required: errorMessage.required,
                                            validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                        }
                                    )} />
                                    {errors.bw_newspaper && <FormHelperText className="text-danger">{errors.bw_newspaper.message}</FormHelperText>}
                                </FormControl>
                                <FormControl className="py-2 ps-4" fullWidth>
                                    <TextField label='Color Newspaper' focused {...register('color_newspaper',
                                        {
                                            required: errorMessage.required,
                                            validate: (value) => validateFloat(value) || 'Please enter a valid float number'
                                        }
                                    )} />
                                    {errors.color_newspaper && <FormHelperText className="text-danger">{errors.color_newspaper.message}</FormHelperText>}
                                </FormControl>
                            </div>
                        </>}
                        <FormControl className="py-2" fullWidth>
                            <InputLabel>Media Score</InputLabel>
                            <Select
                                label="Media Score"
                                defaultValue={5}
                                {...register('score', { required: errorMessage.required })}
                            >
                                {mediaScore.map((i: any, index: any) => <MenuItem key={index} value={i}>{i}</MenuItem>)}
                            </Select>
                        </FormControl>


                        <DialogActions>
                            <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                            <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>SUBMIT</Button>
                        </DialogActions>
                    </form>)
                }
            </DialogContent>
        </Dialog>


        <Dialog open={onConfirm} >
            <DialogTitle>Comfirm {isEditing ? 'Edit' : 'Add'} Media</DialogTitle>
            <DialogContent>
                Are you sure to {isEditing ? 'edit' : 'add'} Media?
                <div className="w-100 d-flex gap-1 justify-content-center py-2">
                    <Button color="error" variant="contained" onClick={() => { setOnConfirm(false); }}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={() => { handleConfirmCreate(); setOnConfirm(false); reset(); }} type="submit">Submit</Button>
                </div>
            </DialogContent>
        </Dialog>
    </>
}

export default ActionMediaInfoComponent;