// axiosInterceptor.ts

import axios from 'axios';

// Set baseURL using environment variable
const baseURL = process.env.REACT_APP_BASE_URL;

if (!baseURL) {
  throw new Error('Base URL is not defined in environment variables.');
}

// Create an instance of Axios with default configurations
const axiosInstance = axios.create({
  baseURL,
});

// Add a request interceptor
const ignorePathname = ['/auth/login']

axiosInstance.interceptors.request.use(
  function (config) {

    if (ignorePathname.includes(config.url ? config.url : '')) {
        return config;
      }

    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
    return Promise.reject(error);
  }
);

export default axiosInstance;
