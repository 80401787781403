import { useEffect, useState } from "react";
import DataTableComponent from "../../components/TableComponent";
import { createProjectAsignmentService, deleteProjectService, editProjectService, getAllProjectWithCompanyService } from "../../services/projectService";
import ActionSubProjectComponent from "./actionSubProjectComponent";
import SearchIcon from '@mui/icons-material/Search';

import './style.css'
import ActionAssignmentProject from "./actionProjectAssignComponent";
import { alertError, alertSuccess } from "../../utils/notification";
import { getUserFindAllService } from "../../services/userService";
import ActionMainProjectComponent from "./actionMainProjectComponent";
import { Box, Button, Modal, Typography } from "@mui/material";


const generateUserAssignmentCircle = (row: any) => {
    const maxItems = 4;
    const extraItems = row.assignmentList.length - maxItems;

    console.log('row' , row)

    return (
        <div className="d-flex">
            {row.assignmentList.slice(0, maxItems).map((item: any, index: number) => (
                // <div
                //     key={item.id}
                //     className="rounded-circle circle-box-shadow"
                //     style={{
                //         width: '40px',
                //         height: '40px',
                //         marginLeft: index === 0 ? '0px' : '-20px',
                //     }}
                // >
                //     <img src="./icon/add.png" alt="" />
                // </div>
                <img 
                    key={item.id}
                    className="rounded-circle circle-box-shadow"
                    style={{
                        width: '40px',
                        height: '40px',
                        marginLeft: index === 0 ? '0px' : '-20px',
                    }}
                    src={ item?.imgUrl ? item?.imgUrl : "./icon/no-img-user.png" } alt="" />
            ))}
            {extraItems > 0 && (
                <div
                    className="circle-box-shadow-plus rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                        width: '40px',
                        height: '40px',
                        marginLeft: '-40px',
                        color: 'white',
                    }}
                >
                    +{extraItems}
                </div>
            )}
        </div>
    )
}


const ProjectPage: React.FC = () => {

    // for search , table
    const [rows, setRows] = useState<any[]>([]);
    const [rowsUers, setRowsUsers] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // for assign
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [currentAssignUser, setCurrentAssignUser] = useState<any[]>([]);
    const [currentSelectProject, setCurrentSelectProject] = useState<any>();

    // for edit
    const [openActionModal, setOpenActionModal] = useState(false);
    const [currentSelectedProject, setCurrentSelectedProject] = useState<any>();

    // for delete
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);



    const handleConfirmActionModal = async (data: any) => {
        setOpenActionModal(false);
        try {
            await editProjectService(data);
            await fetchDataAfterProjectGeneration();

            alertSuccess('Update success fully');
        } catch (error: any) {
            console.log(error)
            alertError('Service Error!');
        }
    };


    const fetchDataAfterProjectGeneration = async () => {
        try {
            const data = await getAllProjectWithCompanyService();
            setRows(data);
        } catch (err: any) {
            console.log('err');
        }
    };



    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = rows.filter((row) => {
        return (
            row.id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
            row.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });


    const columns = [
        {
            name: 'Project',
            selector: (row: any) => row.title,
            sortable: true,
        },
        {
            name: 'SubProject',
            selector: (row: any) => <>
                {row?.sub_project.length > 0 ? <div className="badge bg-success">Sub Project are available!</div> : <div className="badge bg-secondary">No Sub Projects available.</div>}
            </>,
            sortable: true,
        },
        {
            name: 'Company',
            selector: (row: any) => row.company_name,
            sortable: true,
        },
        {
            name: 'Assignment',
            selector: (row: any) => <div onClick={() => handleOpenAssignModal(row)} className="d-flex" style={{ cursor: 'pointer' }}>
                {
                    row.assignmentList.length > 0 ? generateUserAssignmentCircle(row) : <div className="d-flex align-items-center justify-content-center" style={{
                        width: '40px',
                        height: '40px',
                    }} ><img src="./icon/add.png" alt="" style={{ width: '20px', height: '20px' }} /></div>
                }
            </div>,
        },
        {
            name: 'Action',
            selector: (row: any) => (
                <div className="d-flex gap-1">
                    <button onClick={() => { setOpenActionModal(true); setCurrentSelectedProject(row); }} className='bg-transparent ms-1' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/edit.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
                    <button onClick={() => { handleOpenDelete(row.id) }} className='bg-transparent' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/delete.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
                </div>
            ),
        },
    ];


      // for assignment
    const onCloasAssignModal = () => {
        setOpenAssignModal(false);
    }
    const handleConfirmAssignment = async (data: any) => {
        setOpenAssignModal(false);

        const ids = data.map((user: any) => user.id);
        try {
            let payload = {
                userId: ids,
                projectId: currentSelectProject
            }
            await createProjectAsignmentService(payload);
            fetchDataAfterProjectGeneration();

            alertSuccess('Update successfully')
        } catch (error: any) {
            console.log(error)
            alertError('Service Error')
        }

    }
    const handleOpenAssignModal = (row: any) => {

        row.assignmentList.map((item: any) => item.fullName = item.firstName + ' ' + item.lastName)
        setOpenAssignModal(true);
        setCurrentAssignUser(row.assignmentList);
        setCurrentSelectProject(row?.id);
    }



    // delete process

    const handleOpenDelete = (project_id: number) => {
        setProjectId(project_id);
        setOpenDeleteModal(true);
    };
    const handleCloseDelete = () => {
        setOpenDeleteModal(false);
        setProjectId(null);
        setError(null);
    };

    const handleDelete = async () => {
        if (projectId !== null) {
            try {
                await deleteProjectService(projectId);
                fetchDataAfterProjectGeneration();
                handleCloseDelete();

                alertSuccess('Delete Successfully')
            } catch (error: any) {
                setError('Failed to delete project.');
                alertSuccess('Service Fail')
            }
        }
    };



     useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAllProjectWithCompanyService();
                const userList = await getUserFindAllService();
                userList.map((i: any) => i.fullName = i.firstName + ' ' + i.lastName)
                setRowsUsers(userList);
                setRows(data);

                console.log(rows)
            } catch (err: any) {
                console.log(err);
                alertError('Service Error')
            }
        };
        fetchData();
    }, []);




    return (
        <div className="py-2">
            <div className="container-fluid card p-2">
                <div className='row mb-2'>
                    <div className='col-6'>
                        <div className='position-relative'>
                            <SearchIcon className='position-absolute' style={{ top: '50%', left: '20px', transform: 'translate(-50%, -50%)    ' }} />
                            <input
                                className='form-control h-100 rounded-pill'
                                type="text"
                                placeholder="Search.."
                                value={searchQuery}
                                onChange={handleSearch}
                                style={{ maxWidth: '50%', paddingLeft: '30px' }}
                            />
                        </div>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <ActionSubProjectComponent refreshProjects={fetchDataAfterProjectGeneration} />
                    </div>
                </div>

                <DataTableComponent data={filteredRows} onDataUpdate={fetchDataAfterProjectGeneration} columns={columns} currentPage={"projects"} />

                <ActionAssignmentProject
                    open={openAssignModal}
                    onClose={onCloasAssignModal}
                    onSubmit={(data) => {
                        handleConfirmAssignment(data)
                    }}
                    Data={currentAssignUser}
                    UserList={rowsUers}
                />

                <ActionMainProjectComponent
                    open={openActionModal}
                    onClose={() => { setOpenActionModal(false) }}
                    onSubmit={(data) => {
                        handleConfirmActionModal(data)
                    }}
                    Data={currentSelectedProject}
                />

                {/* Delete Modal */}
                <Modal
                    open={openDeleteModal}
                    onClose={handleCloseDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {error ? 'Error' : 'Confirm Deletion'}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {error ? error : 'Are you sure you want to delete ?'}
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            {!error && (
                                <>
                                    <Button onClick={handleCloseDelete} sx={{ mr: 1 }}>Cancel</Button>
                                    <Button color="error" onClick={handleDelete}>Delete</Button>
                                </>
                            )}
                            {error && (
                                <Button onClick={handleCloseDelete}>Close</Button>
                            )}
                        </Box>
                    </Box>
                </Modal>

            </div>
        </div>
    );
};

export default ProjectPage;