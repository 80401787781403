import { Document, Page, Text, View, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import HeaderPDF from './header';
import FooterPDF from './footer';

Font.register({
    family: 'NotoSansThai',
    fonts: [
      { src: require('./NotoSansThai-Regular.ttf') },
      { src: require('./NotoSansThai-Bold.ttf'), fontWeight: 'bold' },
    ],
});

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 10,
        fontFamily: 'NotoSansThai',
        backgroundColor: 'white'
    },
    sectionHeader: {
       
    },
    sectionContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
        
    },
    sectionFooter: {

    },
    imageContainer: {
        alignSelf: 'center',
        marginTop: 10,
        marginBottom: 10,
        padding: 10,
        // borderRadius: 5,
        // borderWidth: 1,
        // borderColor: 'rgba(0, 0, 0, 0.15)',
        // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },


    table: {
        flexDirection: 'column',
        width: '100%',
        borderWidth: 1,
        borderColor: '#000',
        borderStyle: 'solid',
    },
    tableRow: {
        flexDirection: 'row',
        width: '100%',
    },
    tableCol: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#000',
        borderStyle: 'solid',
    },
    tableCell: {
        margin: 5,
        fontSize: 12,
    },
    boxContent: {
        fontFamily: 'NotoSansThai', // Apply the Thai font
        fontSize: 12,
        textAlign: 'center',
    },
});

interface PDFprops {
    imgList: any[];
    data: any;
}

// Create Document Component
const PdfGenerator: React.FC<PDFprops> = ({ imgList , data }) => {
    const mmToPt = (mm:number) => mm * 2.83465;
    return (
        <Document>
            {
                Array.isArray(imgList) && (
                    imgList[0]?.img_content?.map((item : any, index : number) => (
                        <Page key={index} size={{ width: mmToPt(297), height: mmToPt(420) }} style={styles.page}>
                            <View style={styles.sectionHeader}>
                                <HeaderPDF mediaType={''} data={data} mediaImg={imgList[0]?.img_media} />
                            </View>
                            <View style={styles.sectionContent}>
                                <div style={styles.imageContainer}>
                                    <Image src={`data:${item.contentType};base64,${item.imgBase64}`} style={styles.image} />
                                </div>
                            </View>
                            <View style={styles.sectionFooter}>
                                <FooterPDF total={imgList[0]?.img_content?.length || 0} currentPage={index + 1} />
                            </View>
    
                        </Page>
                    ))
                )
                
            }
        </Document>
    );
}

export default PdfGenerator;
