import React, { useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { createCompanyService } from '../../services/projectService';
import ActionCompanyDialogComponent from './actionCompanyDialogComponent';
import { alertError, alertSuccess } from '../../utils/notification';


interface GeneratUserComponentProps {
    onSuccess: () => void; // Callback function to trigger after successful generation
}

interface CompanyProps {
    id?: number;
    title: string;
    imgSource: string;
}

const GenerateCompanyComponent: React.FC<GeneratUserComponentProps> = ({ onSuccess }) => {
    const [open, setOpen] = useState(false);

    const handleGenerateClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmCreate = async (data : CompanyProps) => {
        try {
            await createCompanyService({ title: data.title , imgSource : data.imgSource });
            alertSuccess('Add successfully');

            onSuccess();
            handleClose();
        } catch (error : any) {
            alertError('Service Error!');
        }
    };

    return (
        <>
            <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button onClick={handleGenerateClick}>+ Add Client</Button>
            </ButtonGroup>
            <ActionCompanyDialogComponent 
                open={open} 
                onClose={handleClose} 
                title={'Add New Company'} 
                buttonText={'Submit'} 
                onSubmit={(data) => {
                    handleConfirmCreate(data);
                }}
            />

        </>
    );
};

export default GenerateCompanyComponent;
