import axiosInstance from "./axiosInterceptor";

const API_ENDPOINTS = {
    createCompany: '/projects/company', 
    getCompany: '/projects/company',
    delCompany: '/projects/company/delete',
    editCompany: '/projects/company/edit',

    createProject: '/projects/create', 
    getProjectById: '/projects/get/', 
    getAllProject: '/projects/get', 
    deleteProject: '/projects/delete/',
    editProject: '/projects/edit',


    getProjectAssignment: '/projects/assignment',
    getUserProjectAssignment: '/projects/assignment/users',
    createProjectAssignment: '/projects/assignment',
    delProjectAssignment: '/projects/assignment/delete',

    getAllProjectWithCompany: '/projects/getAll'
};

export const createCompanyService = async (payload: any) => {
  try {
      const response = await axiosInstance.post(API_ENDPOINTS.createCompany ,payload);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to create company');
  }
};

export const editCompanyService = async (payload: any) => {
    try {
        const response = await axiosInstance.put(API_ENDPOINTS.editCompany ,payload);
        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.message || 'Failed to edit company');
    }
  };


  export const deleteCompanyService = async (companyId: number) => {
    try {
        const response = await axiosInstance.delete(`${API_ENDPOINTS.delCompany}/${companyId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.message || 'Failed to delete company');
    }
  };

export const getCompanyService = async () => {
  try {
      const response = await axiosInstance.get(API_ENDPOINTS.getCompany);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to fetch company');
  }
};






export const createProjectService = async (projectData: any) => {
  try {
      const response = await axiosInstance.post(API_ENDPOINTS.createProject, projectData);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to create project');
  }
};

export const getProjectByIdService = async (projectId: number) => {
  try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.getProjectById}${projectId}`);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to fetch project');
  }
};

export const getProjectService = async () => {
  try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.getAllProject}`);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to fetch project');
  }
};

export const deleteProjectService = async (projectId: number) => {
  try {
      const response = await axiosInstance.delete(`${API_ENDPOINTS.deleteProject}${projectId}`);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to delete project');
  }
};

export const editProjectService = async (projectData: any) => {
  try {
      const response = await axiosInstance.post(API_ENDPOINTS.editProject, projectData);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to edit project');
  }
};

export const editSubProjectService = async (projectData: any) => {
    try {
        const response = await axiosInstance.post(API_ENDPOINTS.editProject, projectData);
        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.message || 'Failed to edit project');
    }
  };

export const getProjectAsignmentService = async () => {
  try {
      const response = await axiosInstance.get(`${API_ENDPOINTS.getProjectAssignment}`);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to fetch project');
  }
};

export const createProjectAsignmentService = async (projectData: any) => {
  try {
      const response = await axiosInstance.post(API_ENDPOINTS.getProjectAssignment, projectData);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to create getProjectAssignment');
  }
};

export const delProjectAsignmentService = async (payload: any) => {
  try {
      const response = await axiosInstance.post(API_ENDPOINTS.delProjectAssignment, payload);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to delete project');
  }
};

export const getUsersProjectAsignmentService = async (projectData: any) => {
  try {
      const response = await axiosInstance.post(API_ENDPOINTS.getUserProjectAssignment, projectData);
      return response.data;
  } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to get getProjectAssignment');
  }

};


//   ------------------------


export const getAllProjectWithCompanyService = async () => {
    try {
        const response = await axiosInstance.get(API_ENDPOINTS.getAllProjectWithCompany);
        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.message || 'Failed to fetch getAllProjectWithCompanyService');
    }
  };
