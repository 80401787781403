import { useEffect, useState } from "react";
import { deleteCompanyService, editCompanyService, getCompanyService } from "../../services/projectService";
import DataTableComponent from "../../components/TableComponent";
import GenerateCompanyComponent from "./generateCompanyComponent";

import SearchIcon from '@mui/icons-material/Search';
import ActionCompanyDialogComponent from "./actionCompanyDialogComponent";
import { alertError, alertSuccess } from "../../utils/notification";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


interface Data {
    id: number;
    title: string;
    imgSource?: string;
}

const CompanyPage: React.FC = () => {
    const [rows, setRows] = useState<Data[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isEditModal, setIsEditModal] = useState(false);
    const [selectedCompanyData, setSelectedCompanyData] = useState<any>(null);

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteCompanyId, setDeleteCompanyId] = useState(null);

    const columns = [
        {
            name: 'Client',
            selector: (row: any) => <div className="d-flex gap-1 align-items-center">
                {row.imgSource ? (<img src={row.imgSource.imgUrl} alt="" className="rounded-circle" style={{ width: '40px', height: '40px' }} />) : (<img src="./icon/no-img.png" alt="" className="rounded-circle" style={{ width: '40px', height: '40px' }} />)}
                <b>{row.title}</b>
            </div>
        },
        {
            name: 'Action',
            headerStyle: { textAlign: 'center' },
            selector: (row: any) => <div>
                <button onClick={() => handleEditClick(row)} className='bg-transparent ms-1' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/edit.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
                <button onClick={() => onOpenDeleteModal(row)} className='bg-transparent' style={{ border: 'none', width: '25px', height: '25px' }}><img src="./icon/delete.png" alt="" style={{ width: '18px', height: '20px' }} /></button>
            </div>,
        },
    ];


    const fetchDataAfterCompanyGeneration = async () => {
        try {
            const data = await getCompanyService();
            setRows(data);
        } catch (err: any) {
            console.log('err');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCompanyService();
                setRows(data);
            } catch (err: any) {
                console.log(err);
            }
        };
        fetchData();
    }, []);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredRows = rows.filter((row) => {
        return (
            row.id.toString().includes(searchQuery.toLowerCase()) ||
            row.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const handleEditClick = (Data: any) => {
        setSelectedCompanyData(Data);
        setIsEditModal(true);
    };


    const handleConfirmEdit = async (data: Data) => {
        setIsEditModal(false);
        try {

            if (data.imgSource) {
                await editCompanyService({ id: data.id, title: data.title, imgSource: data.imgSource });
            } else {
                await editCompanyService({ id: data.id, title: data.title });
            }

            await fetchDataAfterCompanyGeneration();
            alertSuccess('Update successfully');
        } catch (error: any) {
            alertError('Service Error!');
        }
    };

    const handleConfirmDelete = async () => {
        setDeleteModal(false);
        try {
            if (deleteCompanyId) {
                await deleteCompanyService(deleteCompanyId);
                await fetchDataAfterCompanyGeneration();
                alertSuccess('Delete success fully');
            } else {
                alertError('Something went wrong!');
            }
        } catch (error) {
            console.log(error)
            alertError('Service Error!');
        }
    }

    const onOpenDeleteModal = (data: any) => {
        setDeleteModal(true);
        setDeleteCompanyId(data.id)
    }

    return (
        <div className='py-2'>
            <div className='container-fluid card p-2'>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <div className='position-relative'>
                            <SearchIcon className='position-absolute' style={{ top: '50%', left: '20px', transform: 'translate(-50%, -50%)    ' }} />
                            <input
                                className='form-control h-100 rounded-pill'
                                type="text"
                                placeholder="Search.."
                                value={searchQuery}
                                onChange={handleSearch}
                                style={{ maxWidth: '50%', paddingLeft: '30px' }}
                            />
                        </div>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <GenerateCompanyComponent onSuccess={fetchDataAfterCompanyGeneration} />
                    </div>
                </div>
                <DataTableComponent data={filteredRows} columns={columns} currentPage={'users'} />
                <ActionCompanyDialogComponent
                    open={isEditModal}
                    onClose={() => setIsEditModal(false)}
                    onSubmit={(data) => {
                        handleConfirmEdit(data);
                    }}
                    title="Edit Company"
                    buttonText="Update"
                    isEditing={true}
                    Data={selectedCompanyData}
                />

                <Dialog open={deleteModal} onClose={() => { setDeleteModal(false); setDeleteCompanyId(null); }}>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure want to delete this company?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setDeleteModal(false); setDeleteCompanyId(null); }}>Close</Button>
                        <Button color='error' onClick={() => handleConfirmDelete()}>Delete</Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>
    );
};

export default CompanyPage;