import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

interface ActionMainProjectComponentProps {
    open : boolean;
    onClose : () => void;
    onSubmit: (data: any) => void;
    Data? : any;
}

interface ProjectProps {
    title: string;
}

enum errorMessage {
    required = 'this field is required',
    max = 'the field is maximum for',
    min = 'the field is minimum for',
}



const ActionMainProjectComponent: React.FC<ActionMainProjectComponentProps> = ({ open, onClose, onSubmit, Data }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm<ProjectProps>();
    const [onConfirm, setOnConfirm] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const handleFormSubmit: SubmitHandler<ProjectProps> = (data) => {
        setData(data);
        setOnConfirm(true);

    }
    const handleConfirm = () => {
        let id = Data.id;
        onSubmit({ ...data , id });

        setOnConfirm(false);
        reset();
    }

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, reset]);




    return <>
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => { onClose(); reset(); }}>
                <DialogTitle>Edit Project</DialogTitle>
                <DialogContent>
                <form className="d-flex flex-column" onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormControl className="py-2" fullWidth>
                            <TextField defaultValue={Data?.title} label='Firstname' focused {...register('title', { required: errorMessage.required, minLength: { value: 3, message: `${errorMessage.min} 3 character` } })} />
                            {errors.title && <FormHelperText className="text-danger" >{errors.title.message}</FormHelperText>}
                        </FormControl>

                        <DialogActions>
                            <Button onClick={() => { onClose(); reset(); }}>Cancel</Button>
                            <Button color="primary" variant="contained" type="submit" onClick={handleSubmit(handleFormSubmit)}>Update</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog open={onConfirm} >
                <DialogTitle>Comfirm Add User</DialogTitle>
                <DialogContent>
                    Are you sure to add new user?
                    <div className="w-100 d-flex gap-1 justify-content-center py-2">
                        <Button color="error" variant="contained" onClick={() => { setOnConfirm(false) }}>Cancel</Button>
                        <Button color="primary" variant="contained" onClick={() => { handleConfirm(); }} type="submit">Submit</Button>
                    </div>
                </DialogContent>
            </Dialog>
    
    </>

}

export default ActionMainProjectComponent ;