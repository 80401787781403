import React, { useState } from "react";
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { getGenerateUserService } from "../../../services/userService";
import ActionUserDialogComponent from "./actionUserDialogComponent";
import { alertError, alertSuccess } from "../../../utils/notification";

interface GenerateUserResponse {
    id: string;
    password: string;
    role: role;
    username: string;
    firstName: string;
    lastName: string;
}

interface role {
    id: string;
    title: string;
}

interface AddUsersProps {
    firstName: string;
    lastName: string;
    roleId: roleType;
}

enum roleType {
    PR = 'R04',
    HEAD_PR = 'R03',
    STAFF_ADMIN = 'R02',
}

interface GeneratUserComponentProps {
    onSuccess: () => void; // Callback function to trigger after successful generation
}

const GeneratUserComponent: React.FC<GeneratUserComponentProps> = ({ onSuccess }) => {
    const [open, setOpen] = useState(false);
    const [apiResponse, setApiResponse] = useState<GenerateUserResponse | string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const handleGenerateClick = () => {
        setOpen(true);
    };

    const handleConfirm = async (data : AddUsersProps) => {
        setOpen(false);
        try {
            const response: GenerateUserResponse = await getGenerateUserService(data);
            setApiResponse(response);
            setIsError(false);
            onSuccess();

            alertSuccess('Add successfully');

        } catch (error: any) {
            setIsError(true);
            alertError('Service Error!');
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleDialogClose = () => {
        setApiResponse('');
        setIsError(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" aria-label="Basic button group">
                <Button onClick={handleGenerateClick}>+ ADD USER</Button>
            </ButtonGroup>
    
            <ActionUserDialogComponent 
                open={open} 
                onClose={handleCancel} 
                onSubmit={(data) => {
                    handleConfirm(data);
                }}
                    title="Add User"
                    buttonText="Submit" 
            />
            

            {/* detail modal & error modal */}
            <Dialog open={typeof apiResponse === 'object'} onClose={handleDialogClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle className="text-center">Generated Result</DialogTitle>
                <p className="text-center text-danger">Warning! Password will be displayed only once</p>
                <DialogContent>
                    {apiResponse && (
                        <div>
                            <p><b>ID</b>: {(apiResponse as GenerateUserResponse).id}</p>
                            <p><b>firstName</b>: {(apiResponse as GenerateUserResponse).firstName}</p>
                            <p><b>lastName</b>: {(apiResponse as GenerateUserResponse).lastName}</p>
                            <p><b>Username</b>: {(apiResponse as GenerateUserResponse).username}</p>
                            <p><b>Password</b>: <b className="text-danger">{(apiResponse as GenerateUserResponse).password}</b></p>
                            <p><b>Role</b>: {(apiResponse as GenerateUserResponse).role.title}</p>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {isError && <Dialog open={typeof apiResponse === 'string'} onClose={handleDialogClose}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Service Error!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>}
        </>
    );
};

export default GeneratUserComponent;
