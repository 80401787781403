import axiosInstance from "./axiosInterceptor";

const API_ENDPOINTS = {
    findAllClipping: '/clipping/get',    
    findOneClipping: '/clipping/get/',    
    removeClipping: '/clipping',    
    createClipping: '/clipping',    

    loadClippingImg: '/clipping/loadImg',
    convertPdfToImg: '/clipping/pdf-to-img'
};

export const getOneClippingService = async ( id : number) => {
  try {
    const response = await axiosInstance.post(`${API_ENDPOINTS.findOneClipping}${id}`, {});
    return response.data;
  } catch (error : any) {
    throw new Error(error.response?.data?.message);
  }
};

export const getClippingService = async ( payload : any) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.findAllClipping, payload);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};

export const createClippingService = async (payload:any) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.createClipping,payload);
    return response.data;
  } catch (error : any) {
    throw new Error(error.response?.data?.message);
  }
};

export const delClippingService = async (id:string) => {
  try {
    const response = await axiosInstance.delete(`${API_ENDPOINTS.removeClipping}/${id}`);
    return response.data;
  } catch (error : any) {
    throw new Error(error.response?.data?.message);
  }
};

export const fetchImageByUrl = async (payload:any) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.loadClippingImg,payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw new Error('Error fetching image');
  }
};

export const fetchPdf2Img = async (payload:any) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.convertPdfToImg,payload,{
      responseType: 'blob', // Important to handle the response as a binary file
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw new Error('Error fetching image');
  }
};