import React from 'react';
import './App.css';

//styling
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
// router
import { RouterProvider } from 'react-router-dom';
import Router from './routes/Router';

//storage
import { AuthDataProvider } from './routes/AuthDataContext';

//datatable
import { ToastContainer } from 'react-toastify';


// for mui theme
import { ThemeProvider, createTheme, CssBaseline, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#0c768a', 
    },
  },
  components: {

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            backgroundColor: '#f0f0f0', // Custom background color
            color: '#000',              // Custom text color
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#d0d0d0',  // Custom border color
            },
          },
        },
      },
    },
    
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#f0f0f0', // Custom background color
            color: '#000',          // Custom text color
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#d0d0d0',  // Custom border color
            },
          },
        },
      },
    },
  },
});




const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthDataProvider>
        <RouterProvider router={Router} />
        <ToastContainer />
      </AuthDataProvider>  
    </ThemeProvider>
  );
};

export default App;
