import axiosInstance from "./axiosInterceptor";

const API_ENDPOINTS = {
    findAllMediaInfo: '/media/info',  
    createMediaInfo: '/media/info',  
    editMediaInfo: '/media/info',  
    deleteMediaInfo: '/media/info',  

    findAllMedia: '/media',  


};

export const getMediaInfoService = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.findAllMediaInfo);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};


export const createMediaInfoService = async (payload:any) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.createMediaInfo,payload);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};

export const delMediaInfoService = async (mediaId:number) => {
    try {
      const response = await axiosInstance.delete(`${API_ENDPOINTS.deleteMediaInfo}/${mediaId}`);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};

export const editMediaInfoService = async (mediaId:number, payload:any) => {
    try {
      const response = await axiosInstance.put(`${API_ENDPOINTS.editMediaInfo}/${mediaId}`,payload);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};


//media

export const getMediaService = async () => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.findAllMedia);
      return response.data;
    } catch (error : any) {
      throw new Error(error.response?.data?.message);
    }
};